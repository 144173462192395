import {
  STORE_SELETED_SUBJECT,
  UPDATE_SELETED_SUBJECT_STATUS,
  STORE_SUBJECT_ID,
  CLEAR_SELECT_SUBJECT,
  UPDATE_DOCS_REVIEW_IN_SELECT_SUBJECT,
  STORE_SUBJECT_RESOLVEDCOMMENTS,
} from "../actions/subject";
import initialState from "../store/initialState";
import { STORE_QUIZ_ATTEMPT_DATA } from "../actions/quizAttempts";

export default (state = initialState.selectedSubject, action) => {
  switch (action.type) {
    case STORE_SELETED_SUBJECT:
      return {
        ...state,
        ...action.subject,
      };
    case UPDATE_SELETED_SUBJECT_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case STORE_SUBJECT_ID:
      return {
        ...state,
        usubjectId: action.usubjectId,
      };
    case CLEAR_SELECT_SUBJECT:
      return {
        subjectConsent: {
          isKrDone: false,
          isDocReviewed: false,
          cleared: true,
        },
      };
    case UPDATE_DOCS_REVIEW_IN_SELECT_SUBJECT:
      return {
        ...state,
        subjectConsent: {
          ...state.subjectConsent,
          isDocReviewed: true,
        },
      };
    case STORE_QUIZ_ATTEMPT_DATA:
      return {
        ...state,
        isKnowledgeReviewDone: true,
      };
    case STORE_SUBJECT_RESOLVEDCOMMENTS:
      return {
        ...state,
        isCommentsResolved: action.isCommentsResolved,
      };
    default:
      return state;
  }
};
