import React, { useMemo } from "react";
import { TouchableOpacity, Text, View, Platform, Dimensions } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import {
  createStackNavigator,
} from "@react-navigation/stack";
import {
  createCompatNavigatorFactory,
  createSwitchNavigator,
} from "@react-navigation/compat";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import StudySiteAccessInfoScreen from "./studySiteInfoPage";
import ConsentOverviewScreen from "./consentOverviewPage";
import InitialScreen from "../components/InitialScreen";
import StudyInfoScreen from "./studyInfoPage";
import DocumentsScreen from "./documentsPage";
import homeScreen from "./homePage";
import StudyVideoListScreen from "./studyVideoPage";
import KnowledgeReviewScreen from "./knowledgeReviewPage";
import SubjectSignatureScreen from "./subjectSignaturePage";
import WitnessSignatureScreen from "./witnessSignaturePage";
import CounterSignatureScreen from "./counterSignaturePage";
import LanguageSelectScreen from "./languageSelectPage";
import CompletedScreen from "./completedPage";
import ValidateOtpScreen from "./ValidateOtpPage";
import ResetPinScreen from "./ResetPinPage";
import DocumentListScreen from "./documentListPage";
import LARSignatureScreen from "./larSignaturePage";
import SubjectDocumentSignScreen from "./subjectDocumentSignPage";
import CongratulationsScreen from "./congratulationsPage";
import SubjectDetails from "../containers/subjectDetailsPage";
import SubjectRegistrationForm from "./subjectRegistrationFormPage";
import AskForLarPage from "./AskForLarPage";
import CounterSignDoc from "./counterSignDocumentPage";
import AbortConsentScreen from "./abortConsentPage";
import HeaderMenuPopup from "../components/abortConsent/HeaderMenuPopup";
import HandoverToSubjectScreen from "../components/HandoverToSubject";
import HandoverTabTosub from "../components/subjectList/HandoverTabToSub";
import { Colors } from "../constants/ui/colorScheme";
import reconsentHomeScreen from "./reconsentHomePage";
import CaptureDeviceSNoScreen from "./captureDeviceSNoPage";
import SubjectEditScreen from "./subjectEditPage";
import DownloadVideosAndStoreData from "../containers/DownloadVideosAndStoreDataPage";
import SubDocsPage from "../containers/SubDocsPage";
import LoginPage from "./loginPage";
import StudyListPage from "./studyListPage";
import OrgListPage from "./orgListPage";
import ForgotPasswordPage from "./forgotPasswordPage";
import ChangePasswordPage from "./changePasswordPage";
import { fontType } from "../styles/font";
import { FontAwesome } from "@expo/vector-icons";
import {
  adminAuthStackDefaultNavigationOptions,
  adminStackDefaultNavigationOptions,
  consentStackDefaultNavigationOptions,
  wrapScreenAndNavigationOptions,
} from "../utils/navigationUtils";
import { linking } from "../utils/urlConfig";
import AddSubjectScreen from "./addSubjectPage";
import SubjectSignInScreen from "./subjectSignInPage";
import SubjectSignUpScreen from "./subjectSignUpPage";
import SubjectAuth from "../components/SubjectAuth";
import MetaDataLoadingPage from "./metadataLoadingPage";
import SubjectValidateOtpPage from "./SubjectValidateOtpPage";
import SubjectResetPasswordPage from "./SubjectResetPasswordPage";
import DocumentSelectionScreen from "./documentSelectionPage";
import WebAppLandingPage from "../containers/webAppLandingPage";
import NoAccessScreen from "../components/congratulations/NoAccessScreen";
import SessionExpiryPage from "../containers/SessionExpiryPage";
import { navigationRef } from "./RootNavigation";
import ClientNotFound from "../components/congratulations/clientNotFound";
import UserInactivated from "../components/congratulations/userInactivated";
import Header from "../components/common/header";
import HandOverDevice from "../components/congratulations/handoverDevice";
import UserProfile from "./userProfile";
import CancelFlow from "../components/congratulations/cancelFlow";
import ClientInactivated from "../components/congratulations/clientInactivated";
import CommentsReview from "../components/comments/commentsReviewChapter";
import SiteStaffComments from "../components/comments/sitestaffComments";
import PrintNSignFlow from "../components/congratulations/printNSignFlow";
import EllipsisText from "../components/common/ellipsisText";
import AccessNotPermitted from "../components/congratulations/AccessNotPermitted";
import UnauthorizedScreen from "../components/common/UnauthorizedScreen";
export const backgroundColor = Colors.header;
export const tintColor = Colors.background;

const screenWidth = Dimensions.get('window').width;
let headerWidth = 0.2*screenWidth;
if(screenWidth < 469){
  headerWidth = 0.4*screenWidth;
}else if(screenWidth < 768) {
  headerWidth = 0.4*screenWidth;
} else if(screenWidth < 992) {
  headerWidth = 0.4*screenWidth;
} else if(screenWidth < 1030){
  headerWidth = 0.6*screenWidth;
} else {
  headerWidth = 0.2*screenWidth; //This is for desktop browsers
}
 
/*
    This method is used to get header title with styles based on the screen
    Header title will be ellipsis if the text is more than 50 (default value is 50, but can be passed with required character count) characters
*/
export const getHeaderTitle = (
  languages,
  isQuickMenuEnabled,
  isBackButtonEnabled,
  studySite,
  title = ''
) => {
  return (
    <View
      style={{
        flex: 1,
        bottom: Platform.OS === "ios" ? 5 : 0,
        alignSelf: "center",
        marginLeft: isBackButtonEnabled ? -60 : isQuickMenuEnabled ? 60 : 0,
      }}
    >
      <EllipsisText numberOfLines={1} ellipsizeMode="tail" style={{ ...headerTitleTextStyle, width: headerWidth, textAlign: 'center' }} text={title || studySite.studyNumber}/>
    </View>
  );
};

const headerTitleTextStyle = {
  fontWeight: "600",
  // alignSelf: 'center',
  fontFamily: "Inter",
  fontSize: 16,
  textAlign: "center",
  color: "#6E41C0",
};

export const headerTitleStyle = {
  fontWeight: "bold",
  // alignSelf: 'center',
  fontFamily: "Inter",
  fontSize: 22,
  textAlign: "center",
};

export const headerStyle = {
  backgroundColor: backgroundColor,
  height: 64,
  borderBottomWidth: "1px",
};

const HomeTabs = createCompatNavigatorFactory(createMaterialTopTabNavigator)(
  {
    ConsentTab: {
      screen: homeScreen,
      navigationOptions: {
        tabBarLabel: "Consent",
      },
    },
    ReConsentTab: {
      screen: reconsentHomeScreen,
      navigationOptions: {
        tabBarLabel: "Re-Consent",
      },
    },
  },
  {
    tabBarOptions: {
      upperCaseLabel: false,
      activeTintColor: Colors.radioGroup,
      inactiveTintColor: Colors.radioGroup,
      tabBarIcon: {},
      tabStyle: {},
      style: {
        backgroundColor: "white",
      },
      labelStyle: {
        fontSize: fontType("BasicFont"),
        fontFamily: "Inter",
        textTransform: "none",
      },
      indicatorStyle: {
        backgroundColor: Colors.radioGroup,
      },
    },
  }
);

const AdminStack = createCompatNavigatorFactory(createStackNavigator)({
  ClientNotFound: {
    screen: ClientNotFound,
    navigationOptions: (props) => {
      const {
        screenProps: { logoutMenuDialoge, setNavigation },
        navigation,
      } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
      };
    },
  },
  UserInactivated: {
    screen: UserInactivated,
    navigationOptions: (props) => {
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
      };
    },
  },
  ClientInactivated: {
    screen: ClientInactivated,
    navigationOptions: (props) => {
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
      };
    },
  },
  SessionExpiryPage: {
    screen: SessionExpiryPage,
    navigationOptions: (props) => {
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        // headerLeft: null,
        // headerTitle: <Text ></Text>,
      };
    },
  },
  NoAccessScreen: {
    screen: NoAccessScreen,
    navigationOptions: (props) => {
      const {
        screenProps: {
          logoutMenuDialoge,
          appSwitcherMenuDialoge,
          setNavigation,
        },
        navigation,
      } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
      };
    },
  },
  AccessNotPermitted: {
    screen: AccessNotPermitted,
    navigationOptions: (props) => {
      const {
        screenProps: {
          logoutMenuDialoge,
          setNavigation,
        },
        navigation,
      } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerRight: () => (
          <UserProfile
            setNavigation={() => setNavigation()}
            logoutMenuDialoge={() => logoutMenuDialoge()}
            navigation={navigation}
          />
        ),
      };
    },
  },
  OrgListPage: {
    screen: OrgListPage,
    navigationOptions: (props) => {
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
      };
    },
  },
  StudyListPage: {
    screen: StudyListPage,
    navigationOptions: (props) => {
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
      };
    },
  },
  DownloadVideosAndStoreData: {
    screen: DownloadVideosAndStoreData,
    navigationOptions: (props) => {
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerLeft: () => (
          <Header canShowBackIcon={true} navigation={props.navigation} />
        ),
      };
    },
  },
  StudySiteAccessInfoScreen: {
    screen: StudySiteAccessInfoScreen,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerTitle: () => getHeaderTitle([], false, true, {},'Study Site Info'),
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              navigation.navigate("StudyListPage");
            }}
          />
        ),
      };
    },
  },
  HomeTabs: {
    screen: HomeTabs,
    navigationOptions: (props) => {
      const {
        screenProps: {
          logoutMenuDialoge,
          setNavigation,
          languages,
          switchStudyPopUp,
          studySite,
        },
        navigation,
      } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerTitleStyle: { alignSelf: "center", marginLeft: 0 },
        headerTitle: () =>
          getDashBoardTitle(
            languages,
            setNavigation,
            switchStudyPopUp,
            navigation,
            studySite
          ),
        headerBackTitleVisible: false,
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => props.navigation.goBack()}
          />
        ),
        headerRight: () => (
          <UserProfile
            setNavigation={() => setNavigation()}
            logoutMenuDialoge={() => logoutMenuDialoge()}
            navigation={navigation}
          />
        ),
      };
    },
  },
  SubjectDetails: {
    screen: SubjectDetails,
    navigationOptions: (props) => {
      const {
        screenProps: { languages, studySite },
        navigation,
      } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerTitle: () => getHeaderTitle(languages, false, true, studySite),
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              navigation.replace("HomeTabs");
            }}
          />
        ),
      };
    },
  },
  DocumentSelection: {
    screen: DocumentSelectionScreen,
    navigationOptions: (props) => {
      const {
        screenProps: { languages, studySite },
        navigation,
      } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerTitle: () => getHeaderTitle(languages, false, true, studySite),
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              navigation.replace("SubjectDetails");
            }}
          />
        ),
      };
    },
  },
  SiteStaffCommentsReviewScreen: {
    screen: SiteStaffComments,
    navigationOptions: (props) => {
      const {
        screenProps: { languages, studySite },
        navigation,
      } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerTitle: () => getHeaderTitle(languages, false, true, studySite),
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              navigation.replace("SubjectDetails");
            }}
          />
        ),
      };
    },
  },
  CounterSignatureScreen: {
    screen: CounterSignatureScreen,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              const documentId = navigation.getParam("documentId");
              navigation.replace("CounterSignDoc", { documentId: documentId });
            }}
          />
        ),
      };
    },
  },
  WitnessSignatureScreen: {
    screen: WitnessSignatureScreen,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              const documentId = navigation.getParam("documentId");
              navigation.replace("CounterSignDoc", { documentId: documentId });
            }}
          />
        ),
      };
    },
  },
  CounterSignDoc: {
    screen: CounterSignDoc,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              navigation.replace("SubjectDetails");
            }}
          />
        ),
      };
    },
  },
  LanguageSelectScreen: wrapScreenAndNavigationOptions(
    LanguageSelectScreen,
    adminStackDefaultNavigationOptions
  ),
  HandoverToSubjectScreen: {
    screen: HandoverToSubjectScreen,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => props.navigation.goBack()}
          />
        ),
      };
    },
  },
  HandoverTabTosub: {
    screen: HandoverTabTosub,
    navigationOptions: (props) => {
      const {
        screenProps: { languages, studySite },
        navigation,
      } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              navigation.navigate("DocumentSelection");
            }}
          />
        ),
        headerTitle: () => getHeaderTitle(languages, false, true, studySite),
        headerTitleStyle: { headerTitleStyle },
      };
    },
  },
  SubjectEditScreen: {
    screen: SubjectEditScreen,
    navigationOptions: (props) => {
      const {
        screenProps: { languages },
        navigation,
      } = props;
      let fromDocSelection = false;
      fromDocSelection = navigation.getParam("fromDocSelection");
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerStyle: { headerStyle },
        headerTitleStyle: { headerTitleStyle },
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              if (fromDocSelection) {
                if (typeof fromDocSelection == "boolean") {
                  navigation.goBack();
                } else {
                  navigation.replace("SubjectDetails");
                }
              } else {
                navigation.replace("SubjectDetails");
              }
            }}
          />
        ),
      };
    },
  },
  AddSubjectScreen: {
    screen: AddSubjectScreen,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerTitleStyle: {
          ...headerTitleStyle,
        },
        headerStyle: headerStyle,
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              navigation.replace("HomeTabs");
            }}
          />
        ),
      };
    },
  },
  UnauthorizedScreen: {
    screen: UnauthorizedScreen,
    navigationOptions: (props) => {
      return {
        ...adminStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
      };
    },
  },
});

const AdminAuthStack = createCompatNavigatorFactory(createStackNavigator)({
  LoginPage: wrapScreenAndNavigationOptions(
    LoginPage,
    adminAuthStackDefaultNavigationOptions
  ),
  WebAppLandingPage: {
    screen: WebAppLandingPage,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...adminAuthStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
      };
    },
  },
  ChangePasswordPage: {
    screen: ChangePasswordPage,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...adminAuthStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              navigation.replace("LoginPage");
            }}
          />
        ),
      };
    },
  },
  ForgotPasswordPage: {
    screen: ForgotPasswordPage,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...adminAuthStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              navigation.replace("LoginPage");
            }}
          />
        ),
      };
    },
  },
  ValidateOtpScreen: {
    screen: ValidateOtpScreen,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...adminAuthStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              navigation.replace("ForgotPasswordPage");
            }}
          />
        ),
      };
    },
  },
  ResetPinScreen: {
    screen: ResetPinScreen,
    navigationOptions: (props) => {
      const { navigation } = props;
      const OtpId = navigation.getParam("OtpId");
      const otpType = navigation.getParam("otpType");
      const userName = navigation.getParam("userName");
      return {
        ...adminAuthStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              navigation.replace("ValidateOtpScreen", {
                OtpId: OtpId,
                otpType: otpType,
                userName: userName,
              });
            }}
          />
        ),
      };
    },
  },
});
const ConsentStack = createCompatNavigatorFactory(createStackNavigator)({
  ConsentOverviewScreen: wrapScreenAndNavigationOptions(
    ConsentOverviewScreen,
    consentStackDefaultNavigationOptions
  ),
  StudyInfoScreen: wrapScreenAndNavigationOptions(
    StudyInfoScreen,
    consentStackDefaultNavigationOptions
  ),
  DocumentsScreen: wrapScreenAndNavigationOptions(
    DocumentsScreen,
    consentStackDefaultNavigationOptions
  ),
  StudyVideoListScreen: wrapScreenAndNavigationOptions(
    StudyVideoListScreen,
    consentStackDefaultNavigationOptions
  ),
  KnowledgeReviewScreen: wrapScreenAndNavigationOptions(
    KnowledgeReviewScreen,
    consentStackDefaultNavigationOptions
  ),
  CongratulationsScreen: wrapScreenAndNavigationOptions(
    CongratulationsScreen,
    consentStackDefaultNavigationOptions
  ),
  CommentsReviewScreen: wrapScreenAndNavigationOptions(
    CommentsReview,
    consentStackDefaultNavigationOptions
  ),
  SubjectRegistrationForm: wrapScreenAndNavigationOptions(
    SubjectRegistrationForm,
    consentStackDefaultNavigationOptions
  ),
  AskForLarPage: wrapScreenAndNavigationOptions(
    AskForLarPage,
    consentStackDefaultNavigationOptions
  ),
  DocumentListScreen: wrapScreenAndNavigationOptions(
    DocumentListScreen,
    consentStackDefaultNavigationOptions
  ),
  LARSignatureScreen: wrapScreenAndNavigationOptions(
    LARSignatureScreen,
    consentStackDefaultNavigationOptions
  ),
  WitnessSignatureScreen: wrapScreenAndNavigationOptions(
    WitnessSignatureScreen,
    consentStackDefaultNavigationOptions
  ),
  CompletedScreen: wrapScreenAndNavigationOptions(
    CompletedScreen,
    consentStackDefaultNavigationOptions
  ),
  SubDocsPage: wrapScreenAndNavigationOptions(
    SubDocsPage,
    consentStackDefaultNavigationOptions
  ),
  AbortConsentScreen: wrapScreenAndNavigationOptions(
    AbortConsentScreen,
    consentStackDefaultNavigationOptions
  ),
  HeaderMenuPopup: wrapScreenAndNavigationOptions(
    HeaderMenuPopup,
    consentStackDefaultNavigationOptions
  ),
  MetaDataLoading: wrapScreenAndNavigationOptions(
    MetaDataLoadingPage,
    consentStackDefaultNavigationOptions
  ),
  HandoverDevice: {
    screen: HandOverDevice,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...wrapScreenAndNavigationOptions(
          ConsentOverviewScreen,
          consentStackDefaultNavigationOptions
        ),
        headerBackTitleVisible: false,
        headerTitle: null,
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => navigation.navigate("ConsentOverviewScreen")}
          />
        ),
      };
    },
  },
  SubjectSignatureScreen: {
    screen: SubjectSignatureScreen,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...wrapScreenAndNavigationOptions(
          SubjectSignatureScreen,
          consentStackDefaultNavigationOptions
        ),
        headerBackTitleVisible: false,
        headerTitle: null,
        headerLeft: () => (
          <Header
            canShowBackIcon={false}
            navigation={() => navigation.navigate("")}
          />
        ),
      };
    },
  },
  SubjectDocumentSignScreen: {
    screen: SubjectDocumentSignScreen,
    navigationOptions: (props) => {
    const { navigation } = props;
    return {
    ...wrapScreenAndNavigationOptions(
    SubjectDocumentSignScreen,
    consentStackDefaultNavigationOptions
    ),
    headerBackTitleVisible: false,
    headerTitle: null,
    headerLeft: () => (
    <Header
    canShowBackIcon={false}
    navigation={() => navigation.navigate("")}
    />
    ),
    };
    },
  },
  CancelFlow: wrapScreenAndNavigationOptions(
    CancelFlow,
    consentStackDefaultNavigationOptions
  ),
  PrintNSignFlow: wrapScreenAndNavigationOptions(
    PrintNSignFlow,
    consentStackDefaultNavigationOptions
  ),
});

const SubjectAuthStack = createCompatNavigatorFactory(createStackNavigator)({
  SubjectAuthPage: wrapScreenAndNavigationOptions(
    SubjectAuth,
    adminAuthStackDefaultNavigationOptions
  ),
  SubjectSignInScreen: {
    screen: SubjectSignInScreen,
    navigationOptions: (props) => {
      const { navigation } = props;
      return {
        ...adminAuthStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        // headerLeft: () => <Header canShowBackIcon={true}  navigation = {() => props.navigation.goBack()}/>
      };
    },
  },
  SubjectSignUpScreen: wrapScreenAndNavigationOptions(
    SubjectSignUpScreen,
    adminAuthStackDefaultNavigationOptions
  ),
  SubjectValidateOtpPage: {
    screen: SubjectValidateOtpPage,
    navigationOptions: (props) => {
      const { navigation } = props;
      const validationObj = JSON.parse(navigation.getParam("validationObj"));
      const isRemoteConsent = validationObj.isRemoteConsent;
      return {
        ...adminAuthStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        // headerLeft:() => (<HeaderBackButton tintColor='white' onPress={()=>{
        //   if(validationObj.from == 'SUBJECT_SIGNIN') {
        //     navigation.replace('SubjectSignInScreen', { isRemoteConsent: isRemoteConsent });
        //   } else {
        //     navigation.navigate('Consent' ,
        //     {
        //       screen:'SubDocsPage',
        //       params: {
        //         documentSignatureId:  validationObj.subDocSignNavProps.documentSignatureId,
        //         signatureType: validationObj.signatureType
        //       }
        //     });
        //   }
        // }}/>),
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              if (validationObj.from == "SUBJECT_SIGNIN") {
                navigation.replace("SubjectSignInScreen", {
                  isRemoteConsent: isRemoteConsent,
                });
              } else {
                navigation.navigate("Consent", {
                  screen: "SubDocsPage",
                  params: {
                    documentSignatureId:
                      validationObj.subDocSignNavProps.documentSignatureId,
                    signatureType: validationObj.signatureType,
                  },
                });
              }
            }}
          />
        ),
      };
    },
  },
  SubjectResetPasswordPage: {
    screen: SubjectResetPasswordPage,
    navigationOptions: (props) => {
      const { navigation } = props;
      const validationObj = navigation.getParam("validationObj");
      return {
        ...adminAuthStackDefaultNavigationOptions(props),
        headerBackTitleVisible: false,
        // headerLeft:() => (<HeaderBackButton tintColor='white' onPress={()=>{
        //   navigation.replace("SubjectValidateOtpPage", { validationObj }) }}/>),
        headerLeft: () => (
          <Header
            canShowBackIcon={true}
            navigation={() => {
              navigation.replace("SubjectValidateOtpPage", { validationObj });
            }}
          />
        ),
      };
    },
  },
});

const AppRoutes = createSwitchNavigator({
  AdminAuthStack: AdminAuthStack,
  Admin: AdminStack,
  Consent: ConsentStack,
  SubjectAuthStack: SubjectAuthStack,
  InitialScreen: InitialScreen,
});

export const getDashBoardTitle = (
  languages,
  setNavigation,
  switchStudyPopUp,
  navigation,
  studySite
) => {
  return (
    <View
      style={{
        flex: 1,
        bottom: Platform.OS === "ios" ? 5 : 0,
        justifyContent: "center",
        alignSelf: "center",
        alignContent: "center",
        marginLeft: 50,
      }}
    >
      <View
        style={{
          flex: 2,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
          <EllipsisText numberOfLines={1} ellipsizeMode="tail" style={{ ...headerTitleTextStyle, width: headerWidth, textAlign: 'center' }} text={studySite ? studySite.studyNumber : ""}/>
        <TouchableOpacity
          onPress={() => {
            setNavigation(navigation);
            navigation.navigate("Admin", { screen: "StudyListPage" });
          }}
          title="Info"
          color={Colors.tobg}
        >
          <FontAwesome
            name="pencil"
            style={{ fontSize: 14, color: "#6E41C0", marginLeft: 8 }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default function NavigationScreens({ screenProps }) {
  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      fallback={<Text style={{ fontFamily: "Inter" }}>Loading...</Text>}
      screenProps={screenProps}
    >
      <AppRoutes screenProps={screenProps} />
    </NavigationContainer>
  );
}
