import React, {
  useEffect,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Dimensions, FlatList, ScrollView, Text, View } from "react-native";
import CommentViewBox from "./commentViewBox";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { retrieveComments } from "../../actions/comments";
import { Footer } from "native-base";
import TryalButton from "../common/button";
import { chapterButtonStyles } from "../../styles/constStyles";
import { width } from "../common/constants";
import { Colors } from "../../constants/ui/colorScheme";
import { updateCommentsStatus } from "../../actions/subject";
const { height: SCREEN_HEIGHT } = Dimensions.get("window");

const SCREEN_WIDTH = width();
const CommentsPage = forwardRef((props, ref) => {
  const {
    addNewComment,
    isSubjectView = true,
    footer = {},
    screenHeightScale = 0.3,
    onCommentCancelClick = () => null,
    chapterType = null,
    setCommentsResolved = () => null,
  } = props;
  const [comments, setComments] = useState();
  const [editingIndex, setEditingIndex] = useState([]);

  const { isRemoteConsent } = useSelector((state) => state.subject);
  const { selectedSubject } = useSelector((state) => state);
  const { studySiteState } = useSelector((state) => state.studySite);
  const { studySite } = useSelector((state) => state);

  const dispatch = useDispatch();

  const getComments = async (addNewComment) => {
    try {
      const data = await dispatch(
        retrieveComments(selectedSubject?.id)
      );
      if (addNewComment) {
        setComments([{ comment: "", edit: true, id: null }, ...data]);
        setEditingIndex([0]);
      } else {
        setComments(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getComments(addNewComment);
  }, []);

  useEffect(() => {
    verifyResolvedComments();
  }, [comments]);

  const onCommentEdit = (index) => {
    const indexs = [...editingIndex, index];
    setEditingIndex(indexs);
  };

  const removeEditingIndex = (index) => {
    const indexes = [...editingIndex];
    _.remove(indexes, (i) => i === index);
    setEditingIndex(indexes);
  };
  const refreshComments = (index, comment) => {
    const updatedComments = [...comments];
    if (!updatedComments[index].id) {
      _.remove(updatedComments, (c, i) => i == index);
      updatedComments.push({ ...comment });
    } else {
      updatedComments[index] = { ...comment };
    }
    removeEditingIndex(index);
    updateEditingIndex(_.orderBy(updatedComments, "createdTs", "desc"));
    setComments(_.orderBy(updatedComments, "createdTs", "desc"));
  };

  const verifyResolvedComments = () => {
    const { updateCommentsStatus } = props;
    if (!isSubjectView && !_.isEmpty(comments)) {
      const resolved =
        _.filter(comments, (com) => com.resolved)?.length === comments?.length
          ? true
          : false;
      updateCommentsStatus(resolved);
      setCommentsResolved(resolved);
    }
  };

  const onCommentCancel = (index) => {
    const updatedComments = [...comments];
    if (!updatedComments[index].id) {
      _.remove(updatedComments, (c, i) => i == index);
      setComments(updatedComments);
    }
    removeEditingIndex(index);
    if (comments.length == 1 && isSubjectView) {
      onCommentCancelClick();
    }
  };

  const onCommentDelete = (index) => {
    const updatedComments = [...comments];
    _.remove(updatedComments, (c, i) => i == index);
    setComments(updatedComments);
    removeEditingIndex(index);
  };

  useImperativeHandle(ref, () => ({
    onAdd() {
      let loComments = [{ comment: "", edit: true, id: null }, ...comments];
      setComments(loComments);
      updateEditingIndex(loComments);
    },
  }));

  const updateEditingIndex = (comments) => {
    let loEditIndex = [];
    _.forEach(comments, (c, index) => {
      if (c.edit) {
        loEditIndex.push(index);
      }
    });
    setEditingIndex(loEditIndex);
  };

  const updateResponse = (index, details) => {
    console.log(index);
    const updatedComments = [...comments];
    updatedComments[index] = details;
    setComments(updatedComments);
  };
  const CommentList = useMemo(() => {
    console.log(comments);
    const data = _.map(comments, (c, index) => ({
      ...c,
      edit: _.includes(editingIndex, index),
    }));
    return (
      <FlatList
        data={data}
        keyExtractor={(item) => item.id}
        renderItem={({ item, index }) => (
          <CommentViewBox
            commentDetails={item}
            onEdit={(e) => onCommentEdit(index)}
            onCommentSave={(comment) => refreshComments(index, comment)}
            onCommentCancel={() => onCommentCancel(index)}
            isSubjectView={isSubjectView}
            onCommentDelete={() => onCommentDelete(index)}
            updateResponse={(details) => updateResponse(index, details)}
            chapterType={chapterType}
          />
        )}
      />
    );
  }, [comments, editingIndex]);
  return (
    <View>
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          flex: 1,
          width: SCREEN_WIDTH,
        }}
      >
        <ScrollView
          style={
            isSubjectView
              ? [
                  {
                    width: SCREEN_WIDTH * 0.9,
                    height: SCREEN_HEIGHT * screenHeightScale,
                    borderColor: Colors.borderGreyColor,
                    borderWidth: 0,
                  },
                ]
              : [{ height: SCREEN_HEIGHT * 0.6 }]
          }
        >
          {CommentList}
        </ScrollView>
      </View>
      {footer?.show && (
        <Footer style={chapterButtonStyles.footer}>
          <View>
            <TryalButton
              buttonStyle={chapterButtonStyles.button}
              buttonTxtStyle={chapterButtonStyles.buttonTxt}
              title={footer.title}
              onClick={footer.onClick}
            />
          </View>
        </Footer>
      )}
    </View>
  );
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCommentsStatus,
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(CommentsPage);
