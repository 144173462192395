export default {
  WhatToExpect: "期待するもの",
  WhatToDescription: "このアプリでより良くご理解の研究で決めることができます。",
  WhatToFooter: "ご書の署名と日付ご本人の同意書には、ご本人の同意ます。",
  NextChapter: "次章",
  Next: "次へ",
  PreviousChapter: "前章",
  Previous: "前",
  Continue: "続き",
  Help: "助",
  Close: "近",
  Hint: "ヒント",
  HintText: "ビューの一部をご本人の同意がこの質問を参考に答えます。",
  Question: "質問",
  Submit: "提出",
  Congratulations: "おめでとうございます！",
  Cong1:
    'する"をクリックしてください知識です。 問い合せの前に決定したい場合への参加などに聞いてください。研究職に進む前に、署名ます。',
  Cong2: "場合には調査に参加させ続ける]ボタンを下に署名。",
  Cong3:
    "決まっている場合に関与するためではありませんの研究に触れて下さい停止に参加した私たします。",
  StopMyPart: "これからも参加",
  WhoInfC: "者の署名がこのご本人の同意?",
  SubjInfc: "私の最新情報をお知らせします",
  LarInf: "私の法的な代表者の署名すると最新情報をお知らせします",
  SignedBy: "署名",
  SignedOn: "署名し",
  TapToSign: "タップするとサイン",
  SignWarning: "申し込みください。",
  OK: "OK",
  Clear: "明らか",
  Cancel: "消",
  ThankYou:
    "この eConsent にご署名いただきありがとうございます。すべての署名が得られると、署名済みのインフォームド・コンセント・フォームのコピーを受け取ります。ご不明な点がございましたら、サイトまでお問い合わせください",
  ReturnTab: "に戻してくださいデバイスの研究コーディネーター又は研究代表者.",
  DocsToSign: "書類にサイン",
  DocsList: "ドキュメントリスト",
  EndPart: "末ご参加の研究への影響を教えてください。",
  SignHere: "こちらの看板",
  SelectWarn: "を選択してください少なくとも一つのオプションです。 ",
  FirstNameWarn: "最初の名前はできません。",
  LastNameWarn: "最後の名前できません。",
  DOBWarn: "生年月日できない空です。",
  DoneSigning: "を行い",
  Register: "登録",
  SelectDate: "日付選択",
  DidNotSignConsent: "ただ同意",
  ConsentSigned: "同意署名",
  SubjectSignature: "対象の署名",
  GoBackToDoc: "奥書",
  GenderWarn: "男女別できない空です。",
  PhoneNoWarn: "電話番号できません。",
  RegPlacHolder: "ここをタップタイプ",
  StopPartFailed: "いった間違っています。 後ほど再度お試しください。.",
  LARsign: "LAR署名",
  LARName: "正式に承認された代理人の名前",
  LARRelation: "保護者または法定代理人の説明/関係",
  TypeLARName: "ここをタップして名前を入力します",
  TypeLARRelation: "ここをタップして関係を入力します",
  FillWarn: "このフィールドに入力してください。",
  EnterHere: "こちらへお入りください",
  RegistrationForm: "登録用紙",
  SignatureChapter: "署名",
  AreYouSure: "本気ですか？",
  Confirm: "確認する",
  PlaceHolderText: "ここをタップして入力します",
  SaveFail: "保存できませんでした。",
  ValidPhn: "有効な電話番号を入力して下さい。",
  NoData: "コンテンツなし。",
  SelectOption: "少なくとも1つのオプションを選択してください",
  DocFieldWarning: "すべての必須項目を入力してください。",
  PleaseScroll:
    "続行する前に、下にスクロールしてドキュメントを完全に読んでください。",
  IRBApprovedDate: "IRB承認日",
  Version: "バージョン",
  SessionExpired: "セッションの有効期限が切れ！ もう一度ログインしてください。",
  ConsentSubmitSuccess: "同意が正常に送信されました。",
  ConsentSubmitFail: "同意の送信に失敗しました。もう一度やり直してください。",
  NetworkErr: "ネットワークエラー。",
  Username: "ユーザー名",
  Password: "パスワード",
  EnterPassword: "パスワードを入力する",
  EnterUsername: "ユーザーネームを入力してください",
  InvalidUsrName: "無効なユーザー名またはパスワード。",
  UserLocked: "ユーザーはロックされています。",
  SomethingWentWrong: "問題が発生しました。",
  SubjAuthorization: "サブジェクト認証。",
  LarAuthorization: "LAR 認証",
  WitnsAuthorization: "証人の承認",
  SubjIniWarn: "ここにイニシャルを入力してください。",
  Subdec: "下の欄に署名を書いてください。",
  Initials: "イニシャル",
  RadioInitialsVal:
    "オプションを選択し、選択したオプションの横にイニシャルを入力してください。",
  Skip: "スキップ.",
  SkipFail: "スキップできませんでした。",
  DateOfBirth: "生年月日を入力してください",
  WitnessSign: "証人の署名",
  SubjectList: "科目一覧",
  CancelFlow:
    "ありがとうございます。デバイスを診療所の担当者に返却してください。",
  OfficeThankYou:
    "ありがとうございます。同意を確定するために、デバイスを診療所の担当者に返却してください。",
  ReturnToSiteStaff:
    "被験者リストに戻るには、あなたが臨床医であることを確認し、被験者リストに戻るボタンをクリックしてください",
  CommentReview: "コメントレビュー",
  CommentsPending:
    "私たちのシステムで追跡されている未解決のコメントがあります。 サイトのスタッフは通知を受けており、あなたの質問について話し合うために手を差し伸べます。",
  ConsentIncomplete:
    "すべてのコメントが解決されるまで、同意を完了することはできません。同意プロセスを続行できるようになると、メールが届きます。",
  AllCommentsAddressed: "コメントはすべて対処済みです。",
  ContinueWithSubject:
    "インフォームド コンセント プロセスを続行するには、被験者にデバイスを渡してください。",
  Comments: "コメント",
  CommentsEmailSent:
    "参加者に電子メールが送信されました。参加者に連絡して、インフォームド コンセントを完了してください。",
  AllCommentsMarkedResolved:
    "あなたのコメントはすべて解決済みとしてマークされました。",
  AnyQuestionsText:
    "参加を希望するかどうかを決定する前に質問がある場合は、署名プロセスに進む前に治験責任医師またはスタッフにお尋ねください。",
  ContinueParticipation:
    "調査に参加したい場合は、下の [続行] ボタンをタッチして、署名プロセスを開始してください。",
  WrongAnswer: "選択した答えは間違っています。もう一度試してください。",
  PrintNSign:
    "印刷と署名を選択しました。 この書類は手書き署名欄を設けて印刷されます。 参加者が eConsent の内容を確認し、質問があれば話し合う機会があることを確認してください。",
  VerifyPrintNSign:
    "同意が電子システムの外部で署名されたことを確認することを選択しました。 署名されたハードコピーをファイルし、参加者に必ずコピーが提供されるようにしてください。「同意を送信する」を選択すると、これを元に戻すことはできません",
  PrintNSignFlow: "この研究には手動の署名が必要です。",
  UnverifyPrintNSign:
    "この文書の検証を行わないことを選択しました。 [OK] をクリックすると、ドキュメントは検証されず、ドキュメントを印刷できるようになります。 書類を再確認することができます。 同意を提出するにはすべての文書を検証する必要があります。 「同意を送信」ボタンを選択すると、このフィールドは変更できなくなり、文書は検証されたままになります。",
  RemotePrintNSignError:
    "このサイトではインフォームド・コンセントに手動で署名する必要があります。同意を電子メールで送信することはできません。",
  KnowledgeReviewCompletion: "ナレッジレビューが完了しました。",
  InOfficeCommentsPending:
    "私たちのシステムで未処理のコメントが追跡されています。 未解決の問題について話し合って解決するために、デバイスを現場スタッフに返してください。 ",
  CounterSignRequiredSignaturesPending:
    "必要な署名者全員がまだこの文書に署名していません。 必要な署名をすべて集めたら、この画面に戻ってください。",
  Logout: "ログアウト",
  StopConsentPopup:
    "同意を停止し、臨床医をシステムからログアウトしてもよろしいですか?同意を継続するには、ログインが必要です。",
  Yes: "はい",
  No: "いいえ",
  ChangingDataLabel: "以下のデータを変更しています：",
  ReasonForChangeLabel: "変更の理由を入力してください",
  ReasonForChangeWarning: "変更の理由を入力してください。",
  SaveChanges: "変更を保存",
  ReasonForChangeNote:
    "注: 入力した変更理由は、すべての変更に適用されます。変更の別の理由を入力する場合は、[X] をクリックしてこのウィンドウを閉じ、個別のトランザクションで変更を行います。変更理由フィールドに保護されたデータを含めないでください。",
  ReasonForChangeDetails: "変更理由の詳細",
  ReasonForChange: "変更理由",
  Verify: "確かめる",
  UserId: "ユーザー ID",
  EnterUserId: "UserIdを入力",
  ClinicianAuthorization: "臨床医認定",
  SubmitConsent: "同意を送信",
  AccessNotPermitted: "アクセスは許可されていません",
  ApologyForNotPermit:
    "申し訳ございませんが、現在のロールでは、このアプリケーションへのアクセスは許可されていません。",
  ContactAdminstrator:
    "ご不明な点がございましたら、治験のプロジェクトマネージャーにお問い合わせください。",
  UnAuthorizedMessage: "このページを表示する権限がありません。",
  ProccedFail: '続行できませんでした。',
};
