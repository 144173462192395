export default {
  WhatToExpect: "What to Expect",
  WhatToDescription:
    "This app can help you better understand the study, so you can decide if you want to participate.",
  WhatToFooter:
    "You will receive a copy of the signed and dated informed consent form at the end of the informed consent process.",
  NextChapter: "NEXT CHAPTER",
  Next: "NEXT",
  PreviousChapter: "PREVIOUS CHAPTER",
  Previous: "PREVIOUS",
  Continue: "CONTINUE",
  Help: "Help",
  Close: "CLOSE",
  Hint: "HINT",
  HintText:
    "View the part of the informed consent that this question references and answer again.",
  Question: "Question",
  Submit: "SUBMIT",
  Congratulations: "Congratulations!",
  Cong1:
    "You have completed the Knowledge Review. If you have any questions before deciding if you want to participate, please ask the study investigator or staff before proceeding to the signature process.",
  Cong2:
    "If you would like to participate in the study, please touch the Continue button below to begin the signature process.",
  Cong3:
    "If you have decided not to participate in the study, please touch Stop My Participation button below.",
  StopMyPart: "STOP MY PARTICIPATION",
  WhoInfC: "Who is signing this informed consent ?",
  SubjInfc: "I will sign the informed consent.",
  LarInf:
    "My legally acceptable representative will sign the informed consent.",
  SignedBy: "Signed By",
  SignedOn: "Signed On",
  TapToSign: "TAP TO SIGN",
  SignWarning: "Please sign to continue",
  OK: "OK",
  Clear: "CLEAR",
  Cancel: "CANCEL",
  ThankYou:
    "Thank you for signing this eConsent. When all signatures are obtained, you will receive a copy of your signed Informed Consent Form. If you have any questions please contact your site.",
  ReturnTab:
    "Please return the device back to the study coordinator or investigator.",
  DocsToSign: "Documents to Sign",
  DocsList: "Documents List",
  EndPart: "End your participation in the study ?",
  SignHere: "Sign Here",
  SelectWarn: "Please select at least one option. ",
  FirstNameWarn: "First Name cannot be empty.",
  LastNameWarn: "Last Name cannot be empty.",
  DOBWarn: "Date of Birth cannot be empty.",
  DoneSigning: "DONE",
  Register: "REGISTER",
  SelectDate: "Select date",
  DidNotSignConsent: "Did not sign Consent",
  ConsentSigned: "Consent Signed",
  SubjectSignature: "Subject Signature",
  GoBackToDoc: "BACK TO DOCUMENT",
  GenderWarn: "Gender cannot be empty.",
  PhoneNoWarn: "Phone Number cannot be empty.",
  RegPlacHolder: "Tap here to type",
  StopPartFailed: "Something went wrong. Please try again later.",
  LARsign: "LAR Signature",
  LARName: "Name of the Legally Authorized Representative, in full",
  LARRelation:
    "Description/Relationship of Guardian or Legally Authorized Representative",
  TypeLARName: "Tap here to type name",
  TypeLARRelation: "Tap here to type relationship",
  FillWarn: "Please fill this field.",
  EnterHere: "Enter here",
  RegistrationForm: "Registration Form",
  SignatureChapter: "Signature",
  AreYouSure: "Are you sure?",
  Confirm: "Confirm",
  PlaceHolderText: "Tap here to type",
  SaveFail: "Failed to save.",
  ValidPhn: "Please enter a valid Phone Number.",
  NoData: "No Content.",
  SelectOption: "Please select at least one option.",
  DocFieldWarning: "Please complete all mandatory fields.",
  PleaseScroll:
    "Please scroll down and read the document completely before continuing.",
  IRBApprovedDate: "IRB Approved Date",
  Version: "Version",
  SessionExpired: "Session Expired! Please login again.",
  ConsentSubmitSuccess: "Consent submitted successfully.",
  ConsentSubmitFail: "Consent Submission failed, Please try again.",
  NetworkErr: "Network Error.",
  Username: "Username",
  Password: "Password",
  EnterPassword: "Enter Password",
  EnterUsername: "Enter Username",
  InvalidUsrName: "Invalid username or password.",
  UserLocked: "User locked.",
  SomethingWentWrong: "Something went wrong.",
  SubjAuthorization: "Subject Authorization",
  LarAuthorization: "LAR Authorization",
  WitnsAuthorization: "Witness Authorization",
  SubjIniWarn: "Please enter your initials here.",
  Subdec: "Please write your signature in the space below.",
  Initials: "Initials",
  RadioInitialsVal:
    "Please select an option and enter initial next to the option you have selected.",
  Skip: "Skip",
  SkipFail: "Failed to skip.",
  DateOfBirth: "Enter Date of Birth",
  CredentialValidationPass: "Credentials validated successfully.",
  CredentialValidationFail: "Credential validation failed.",
  WitnessSign: "Witness Signature",
  SubjectList: "Subject List",
  CancelFlow:
    "Thank you, please hand the device back to the clinic representative.",
  OfficeThankYou:
    "Thank you, please hand the device back to the clinic representative in order to finalize consent.",
  ReturnToSiteStaff:
    "To return to the subject list, please confirm you are the clinician and click the return to subject list button",
  CommentReview: "Comment Review",
  CommentsPending:
    "You have outstanding comments tracked in our system. The  site staff has been notified and will reach out to discuss your questions or you can contact them at ",
  ConsentIncomplete:
    "Your consent cannot be completed until all comments are resolved. You will receive an email when you can continue the consent process.",
  AllCommentsAddressed: "All comments have been addressed.",
  ContinueWithSubject:
    "Please give the device to the subject to continue with the informed consent process.",
  Comments: "Comments",
  CommentsEmailSent:
    "An email has been sent to the participant, please contact them to complete the Informed Consent.",
  AllCommentsMarkedResolved: "All of your comments have been marked resolved.",
  AnyQuestionsText:
    "If you have any questions before deciding if you want to participate, please ask the study investigator or staff before proceeding to the signature process.",
  ContinueParticipation:
    "If you would like to participate in the study, please touch the Continue button below to begin the signature process.",
  WrongAnswer: "The answer you selected is incorrect, please try again.",
  PrintNSign:
    "You have selected print and sign. This document will be printed with the space for handwritten signatures. Please ensure the participant has reviewed the eConsent content and has had the opportunity to discuss any questions.",
  VerifyPrintNSign:
    'You have selected to verify that the consent has been signed outside of the electronic system. Please file the signed hard copy and ensure the participant is provided a copy. Once you have selected "Submit consent" this cannot be undone',
  PrintNSignFlow: "This study requires manual signature.",
  UnverifyPrintNSign:
    'You have selected to un-verify this document. When you click OK the document will not be verified and you will be able to print the document. You will be able to re-verify the document. All documents must be verified to submit consent. Once you have selected "submit consent" button this field will not be changeable, the document will remain verified.',
  RemotePrintNSignError:
    "This site requires manual signatures on the Informed Consent, you can not email the consent.",
  KnowledgeReviewCompletion: "You have completed the Knowledge Review.",
  InOfficeCommentsPending:
    "You have outstanding comments tracked in our system. Please hand the device back to the site staff to discuss and resolve outstanding issues. ",
  CounterSignRequiredSignaturesPending:
    "All of the required signatories have not yet signed this document. Please return to this screen once all of the required signatures have been collected.",
  Logout: "LOGOUT",
  StopConsentPopup:
    "Are you sure you want to stop consent and log the clinician out of the system? Login will be required for you to continue consent.",
  Yes: "Yes",
  No: "No",
  ChangingDataLabel: "You are changing the following data:",
  ReasonForChangeLabel: "Please Enter Reason for Change",
  ReasonForChangeWarning: "Reason for change is required.",
  SaveChanges: "SAVE CHANGES",
  ReasonForChangeNote:
    "Note: The entered reason for change will apply to all changes. If you wish to enter different reasons for change, click the X to close this window and make the changes in separate transactions. Do not include protected data in the reason for change field.",
  ReasonForChangeDetails: "Reason For Change Details",
  ReasonForChange: "Reason for Change",
  Verify: "VERIFY",
  UserId: "User ID",
  EnterUserId: "Enter User ID",
  ClinicianAuthorization: "Clinician Authorization",
  SubmitConsent: "Submit Consent",
  AccessNotPermitted: "Access Not Permitted",
  ApologyForNotPermit:
    "We apologize, but your current role does not permit access to this application.",
  ContactAdminstrator:
    "For assistance please contact your Study’s Project Manager.",
  UnAuthorizedMessage: "You are not authorized to view this page.",
  ProccedFail: 'Failed to proceed.',
};
