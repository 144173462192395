import OrgListScreen from '../components/organization/orgListScreen';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setOrganizationName } from '../actions/clientUser';

class OrgListPage extends Component {
    


    render() {
        const { navigation, screenProps, selectedUser,setOrganizationName} = this.props;
        return (
            <OrgListScreen 
                navigation={navigation}
                screenProps={screenProps}
                selectedUser={selectedUser}
                setOrganizationName = {setOrganizationName}
            />
        );
    }
}

const mapStateToProps = state => ({
    selectedUser: state.clientUser.selectedUser,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setOrganizationName
    },
    dispatch,
);



export default connect(mapStateToProps, mapDispatchToProps)(OrgListPage);
