import React, { useMemo } from 'react';
import TryalIcon from '../common/icon';
import { IconType } from '../common/constants';
import { View, StyleSheet, Platform } from 'react-native';
import { Colors } from '../../constants/ui/colorScheme';
import { useDispatch, useSelector } from 'react-redux';
import { resolveComment } from '../../actions/comments';
import ToolTip from "../dashboard/Tooltip";

const ReviewIconStyles= StyleSheet.create({
    containerStyle:{
        display: 'flex',
        height: 20,
        width: 15,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: Colors.buttonwithbg,
    },
});

const ReviewIcon = (props) => {
    const { isReviewed, isSubjectView, onResolve} = props
    if (!isReviewed) {
        if (Platform.OS === 'web') {
            return (
        <ToolTip tip='Resolve Comment'>
            <View style = {[ReviewIconStyles.containerStyle, {backgroundColor: Colors.background}]}>
                <TryalIcon iconName='check' iconType={IconType.MATERIAL} color={  Colors.buttonwithbg} size={14} disabled={isSubjectView || isReviewed ? true: false} onClick={onResolve}/> 
            </View>
        </ToolTip>
    )
        } else {
            return (
                <View style = {[ReviewIconStyles.containerStyle, {backgroundColor: Colors.background}]}>
                <TryalIcon iconName='check' iconType={IconType.MATERIAL} color={  Colors.buttonwithbg} size={14} disabled={isSubjectView || isReviewed ? true: false} onClick={onResolve}/> 
            </View>
            )
        }
    
    }
    return (
        <View style = {[ReviewIconStyles.containerStyle, {backgroundColor: Colors.buttonwithbg }]}>
           <TryalIcon iconName='check' iconType={IconType.MATERIAL} color={ Colors.background } size={14} disabled={isSubjectView || isReviewed ? true: false} onClick={onResolve}/> 
        </View>
    )
}
const CommentReviewActions = React.memo((props) => {
    const { isReviewed , isSubjectView , commentDetails, onCommentSave} = props;
    const { isRemoteConsent } = useSelector(state => state.subject);
    const { selectedSubject, studySite} = useSelector(state => state);
    const dispatch = useDispatch();

    const onResolve = async (resolved) => {
        try {
            const request = {
                id: commentDetails.id,
                resolved: resolved,
                subjectId: commentDetails.subjectId,
                subjectConsentId: selectedSubject?.subjectConsent.id,
            }
        const res = await dispatch(resolveComment(selectedSubject?.id, request, isRemoteConsent))
        onCommentSave({...commentDetails, resolved: res?.resolved})
        } catch (error) {
            console.log(error);
        }
    }

    if(isReviewed){
       return (<React.Fragment>
        
            { !isSubjectView && Platform.OS === 'web' && <ToolTip  tip='Unresolve Comment'><TryalIcon iconName='refresh' iconType={IconType.MATERIAL} disabled={isSubjectView? true: false} onClick={() => onResolve(false)}/>
            </ToolTip>}
            { !isSubjectView && Platform.OS !== 'web' && <TryalIcon iconName='refresh' iconType={IconType.MATERIAL} disabled={isSubjectView? true: false} onClick={() => onResolve(false)}/>}
            <ReviewIcon isReviewed = {isReviewed} onResolve={() =>onResolve(true)} isSubjectView={isSubjectView}/>
        </React.Fragment>
       )
    }
    return  (
        <ReviewIcon isReviewed = {false} onResolve={() =>onResolve(true)} isSubjectView={isSubjectView}/>
    )
})

export default CommentReviewActions;
