import React, { Component } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, ScrollView, TouchableOpacity, FlatList, Dimensions } from 'react-native';
import { Container, Row, Col, Grid, Button, Picker, Item, ListItem, Body, CheckBox } from 'native-base';
import _ from 'lodash';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

//import DatePicker from 'react-native-datepicker';
import showToast from "../../utils/toast";
import { renderStatusName } from './statusIconsAndNames';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { fontType } from '../../styles/font';
import { constStyles } from '../../styles/constStyles';
import { MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons";
import MyLoader from '../../utils/webLoader';
import { formatDate } from '../../utils/dateFormatUtils'

// import DatePk from '../../utils/webDatePicker';


import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import moment from 'moment';
import { TextInput } from 'react-native';
import { Colors } from '../../constants/ui/colorScheme';
import { ConsentDataContext } from '../../providers/ConsentDataProvider';
import { getConsentData, getLanguageIdByLocale, getConsentVersionId } from '../../utils/consentData';
import api from '../../utils/api';
// import PhoneNumberInput from '../../utils/phoneNumberInput';
// import DatePk from '../../utils/webDatePicker';
import Dialog, { DialogContent, DialogFooter, DialogButton, DialogTitle } from 'react-native-popup-dialog';
import Collapsible from 'react-native-collapsible';
import RadioButton from '../../components/documents/RadioButton';
import LanguageSelection from '../../containers/LanguageSelection';
import constants from "../../utils/constants.js";
import SubjectDetailsReasonForChange from './SubjectDetailsReasonForChange.js';
import { isAnyValueUpdated } from '../common/ReasonForChange/utils.js';
const { ContextProperties } = constants

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

const Todaydate = new Date(Date.now());


class AddSubject extends Component {
  static contextType = ConsentDataContext;
  constructor(props) {
    super(props)
    this.state = {
      phoneNumber: '',
      date: null,
      language: undefined,
      isloading: false,
      showLanguagesPopup: false,
      LanguageValue: null,
      larVisible: false,
      isLarWitness: false,
      witnessVisible: false,
      borderColor: '#c9c9c9',
      showReasonForChangeModal: false,
    };
  };


  componentDidMount() {
    const { data, fromEdit } = this.props;
    const dob = data.dob ? new Date(moment(data.dob).format("YYYY-MM-DD")) : null;
    const LanguageValue = data.locale ? data.locale : null;
    this.setState({ date: dob, LanguageValue: LanguageValue });
    if(data.larFirstName!=undefined && data.larFirstName.length != 0){
      this.setState({
        larVisible: true
      })
    } else {
      this.setState({
        larVisible: false
      })
    }
    if(data.witnessFirstName!=undefined && data.witnessFirstName.length != 0){
      this.setState({
        witnessVisible: true
      })
    } else {
      this.setState({
        witnessVisible: false
      })
    }
  }


  validatePhoneNumber = (value) => {
    // const { screenProps: { t } } = this.props;
    const phoneNo = parsePhoneNumberFromString('+' + value);
    if (phoneNo === undefined || !phoneNo.isValid() === true) {
      return false
    } else {
      return true
    }
  };

  // changeDate = (date, setFieldvalue) => {
  //   if(Platform.OS === 'web' ) {
  //     setFieldvalue('dob', date);   
  //   } else {
  //     setFieldvalue('dob', new Date(moment(date).format("YYYY-MM-DD")));
  //   }
  //   this.setState({ date }); 
  // };

  setWitnessValues = (larFirstName,larLastName,larEmail,setFieldValue) => {
    const {isLarWitness}= this.state;
    if(isLarWitness) {
      setFieldValue('witnessFirstName',larFirstName);
      setFieldValue('witnessLastName',larLastName);
      setFieldValue('witnessEmail',larEmail); 
    } else {
      setFieldValue('witnessFirstName','');
      setFieldValue('witnessLastName','');
      setFieldValue('witnessEmail','');

    }
  }
  
  // changeDateValidation = (date) => {
  //   this.setState({ isValidDate: false });
  // };

  setInitialValues = () => {
    const { data, fromEdit } = this.props;
    const initialData = { firstName: '', middleName: '', lastName: '', dob: '', phoneNo: '', email: '', locale: '',larFirstName:'',larLastName:'',larRelation: '',larEmail:'',witnessFirstName:'',witnessLastName:'',witnessEmail:'',larAsWitness:false };

    if (fromEdit) {
      const dob = data.dob ? new Date(moment(data.dob).format("YYYY-MM-DD")) : '';
      const subject = {
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        dob: dob,
        phoneNo: data.phoneNo || '',
        email: data.email || '',
        locale: data.locale,
        usubjectId: data.usubjectId,
        larFirstName: data.larFirstName,
        larLastName: data.larLastName,
        larRelation: data.larRelation,
        larEmail: data.larEmail || '',
        witnessFirstName: data.witnessFirstName,
        witnessLastName: data.witnessLastName,
        witnessEmail: data.witnessEmail || '',
        larAsWitness: data.larAsWitness
      };
      
      return subject;
    } else {

      return initialData;
    }
  }


  onReasonForChangeSave = (reasonForChange, values) => {
    this.updateSubject(values, reasonForChange);
    this.setState({
      showReasonForChangeModal: false
    })
  }

  getValueOfField = (key, value) => {
    if(_.isEqual(key, 'dob')){
      return value ? moment(value).format("DD-MMM-YYYY") : null
    }
    else if(_.isEqual(key, 'phoneNo')){
      return _.isEqual(value, '+') ? "" : value
    }
    return value
  }

  onSubmit = (values) => {
    const { data, fromEdit } = this.props;
    const isChangesExists = isAnyValueUpdated(data, values, this.getValueOfField)
    if (fromEdit) {
      this.setState({
        showReasonForChangeModal: isChangesExists,
      })
    }
    if(!isChangesExists && fromEdit){
      this.updateSubject(values, null);
    }
    if(!fromEdit){
      this.saveSubject(values);
    }
  }

  saveSubject = async (values) => {
    const { studySiteId, deviceNo, navigation, languages,
      storeSelectedSubjectDetailsinSubject, storeSelectedSubject } = this.props;

    const languageId = getLanguageIdByLocale(languages, values.locale);
    const ConsentData = getConsentData(this.context.languages);

    const subject = {
      ...values,
      status: 'NEEDS_SIGNATURE',
      registered: true,
      studySite: {
        id: studySiteId
      },
      languageId: languageId,
      currentConsentVersionId: getConsentVersionId(this.context.languages),
      phoneNo: _.isEqual(values.phoneNo, '+') ? "" : values.phoneNo,
    };
    const subjectConsent = {
      id: null,
      status: 'NEEDS_SIGNATURE',
      consentVersion: {
        id: ConsentData.consentVersionId,
      },
      consentVersionLanguage: {
        id: languageId,
      },
      consentVersionName: ConsentData.consentVersionName,
      consentName: ConsentData.consentName,
      // supervisedBy: user.fullName,
    };

    try {
      this.setState({
        isloading: true
      })
      const res = await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/register`
        , { subject, subjectConsent, deviceNo });
      let loSubject = res.data;
      loSubject = {
        ...loSubject,
        subjectConsentId: loSubject.subjectConsent.id,
        consentVersionId: loSubject.currentConsentVersionId,
        isReconsent: loSubject.subjectConsent.reconsent,
      }
      const selSubject = {
        firstName: loSubject.firstName,
        lastName: loSubject.lastName,
        fullName: loSubject.fullName,
        middleName: loSubject.middleName,
        id: loSubject.id,
        isRegistered: true,
        subjectConsentId: loSubject.subjectConsent.id,
        consentVersionId: loSubject.currentConsentVersionId,
        status: loSubject.status,
        isReconsent: loSubject.subjectConsent.reconsent,
        lastConsentedIrbVersion: loSubject.lastConsentedIrbVersion,
        consentedOn: loSubject.consentedOn,
        navTour: [],
        larRelation : loSubject.larRelation,
      }
      storeSelectedSubjectDetailsinSubject(selSubject);
      storeSelectedSubject(loSubject);
      this.setState({
        isloading: false
      })
      showToast("Subject registered successfully.", "success", 2000);
      navigation.replace('SubjectDetails');
    } catch (e) {
      this.setState({
        isloading: false
      })
      if (e.response) {
        showToast("failed to save.", "danger", 2000);
      } else {
        showToast("Network Error.", "danger", 2000);
      }
      console.log(e);
    }
  }

  updateSubject = async (values, reasonForChange) => {
    this.setState({
      isloading: true
    })
    const {fromDocSelection, navigation, storeSelectedSubject, languages, data: selectedSubject } = this.props;
    const languageId = getLanguageIdByLocale(languages, values.locale);
    const subject = {
      ...values,
      phoneNo: _.isEqual(values.phoneNo, '+') ? "" : values.phoneNo,
      reasonForChange,
      languageId: languageId,
      id: selectedSubject.id
    };
    try {
      this.setState({ isloading: true });
      const res = await api.put( `/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${selectedSubject.id}`
        , {subject, subjectConsent: {}});
      if (res.status == 200) {
        this.setState({ isloading: false });
        showToast("Saved successfully.", 'success', 2000);
        let loSubject = res.data;
        storeSelectedSubject(loSubject);
        if(fromDocSelection) {
          if(typeof(fromDocSelection) == "boolean") {
            navigation.goBack();
          } else {
            navigation.replace('SubjectDetails');
          }                  
        }else{
          navigation.replace('SubjectDetails');
        }
      }
    } catch (e) {
      this.setState({ isloading: false });
      if (e.response && e.response.status) {
        const stats = e.response.status 
        if (Number(stats) === 409) {
          showToast("This Subject ID already exists in this study. Please update try again. Contact the help desk if you need assistance.", 'danger', 2000);
        }else {
          showToast("Failed to save.", 'danger', 2000);
        } 
      } else {
        showToast("Failed to save.", 'danger', 2000);
      }
    }
    this.setState({
      isloading: false
    })
  }

  renderIcons = (status) => {
    if (status === "DISCONTINUED") {
      return (
        <MaterialCommunityIcons
          name="close-circle-outline"
          size={fontType('StatusIcon')}
          color={Colors.discontinued}
        />
      );
    } else if (status === "NEEDS_SIGNATURE") {
      return (
        <MaterialCommunityIcons
          name="dots-horizontal-circle"
          size={fontType('StatusIcon')}
          color={Colors.needSign}
        />
      );
    } else if (status === "NEEDS_COUNTER_SIGNATURE") {
      return (
        <MaterialCommunityIcons
          name="circle-slice-4"
          size={fontType('StatusIcon')}
          color={Colors.needsCounterSign}
        />
      );
    } else if (status === "CONSENTED")
      return (
        <MaterialCommunityIcons
          name="checkbox-marked-circle"
          size={fontType('StatusIcon')}
          color={Colors.consentedColor}
        />
      );
    return null;
  };

  getMandatoryIcon = (color) => {
    return <Text style={{ color: color }}>*</Text>
  }

  getValidation = () => {
    const { larVisible,witnessVisible } = this.state;
    if(larVisible && witnessVisible){
      
      return Yup.object({
        firstName: Yup.string().required('Please enter First Name.'),
        lastName: Yup.string().required('Please enter Last Name.'),
        locale: Yup.string().required('Please select Consent Language.'),
        email: Yup.string().email('Please enter a valid Email.'),
        larFirstName: Yup.string().required('Please enter First Name.'),
        larLastName : Yup.string().required('Please enter Last Name.'),
        larEmail : Yup.string().email('Please enter a valid Email.'),
        witnessFirstName: Yup.string().required('Please enter First Name.'),
        witnessLastName : Yup.string().required('Please enter Last Name.'),
        witnessEmail : Yup.string().email('Please enter a valid Email.'),        
      });

    } else if(larVisible) {

      return Yup.object({
        firstName: Yup.string().required('Please enter First Name.'),
        lastName: Yup.string().required('Please enter Last Name.'),
        locale: Yup.string().required('Please select Consent Language.'),
        email: Yup.string().email('Please enter a valid Email.'),
        larFirstName: Yup.string().required('Please enter First Name.'),
        larLastName : Yup.string().required('Please enter Last Name.'),
        larEmail : Yup.string().email('Please enter a valid Email.')
      });    
    } else if(witnessVisible){
      return Yup.object({
        firstName: Yup.string().required('Please enter First Name.'),
        lastName: Yup.string().required('Please enter Last Name.'),
        locale: Yup.string().required('Please select Consent Language.'),
        email: Yup.string().email('Please enter a valid Email.'),
        witnessFirstName: Yup.string().required('Please enter First Name.'),
        witnessLastName : Yup.string().required('Please enter Last Name.'),
        witnessEmail : Yup.string().email('Please enter a valid Email.')
      });
    } else {
      return Yup.object({
        firstName: Yup.string().required('Please enter First Name.'),
        lastName: Yup.string().required('Please enter Last Name.'),
        locale: Yup.string().required('Please select Consent Language.'),
        email: Yup.string().email('Please enter a valid Email.')
      });

    }
  }

  setWitnessOnChangeValues = (setFieldValue,value,fieldName) => {
    setFieldValue(fieldName,value);
    // const s  = getFieldValue('larEmail').value;
    // setFieldValue('larEmail',s);
    
  }

  setLarValues = (setFieldValue, larAsWitness) => {
    const { larVisible } = this.state;
    if (larVisible) {
      setFieldValue('larFirstName', '');
      setFieldValue('larLastName', '');
      setFieldValue('larRelation', '');
      setFieldValue('larEmail', '');
      setFieldValue('larAsWitness', false)
      if (larAsWitness) {
        setFieldValue('witnessFirstName', '');
        setFieldValue('witnessLastName', '');
        setFieldValue('witnessEmail', '');
        this.setState({
          isLarWitness: false,
          witnessVisible: false,
        });
      }
      this.setState({ larVisible: !larVisible });
    } else {
      this.setState({ larVisible: !larVisible });
    }
  }

  setWitnessValuesOnTouch = (setFieldValue) => {
    const { witnessVisible } = this.state;
    if (witnessVisible) {
      setFieldValue('witnessFirstName', '');
      setFieldValue('witnessLastName', '');
      setFieldValue('witnessEmail', '');
      setFieldValue('larAsWitness', false)
      this.setState({ witnessVisible: !witnessVisible });
    } else {
      this.setState({ witnessVisible: !witnessVisible });
    }
  }

    onFocus = () => {
        this.setState({borderColor: '#c9c9c9'})
    }
    

  render() {
    const { isloading, LanguageValue, larVisible, witnessVisible,isLarWitness, showReasonForChangeModal } = this.state
    const { screenProps, languages, data, fromEdit } = this.props;
    const items = languages.map(language => ({
      id: language.id,
      name: language.languageName
    }));
    return (
      <View style={{flex:1}}>
      <ScrollView contentContainerStyle={{flexGrow:1, backgroundColor: '#fff'}} >
        <Container style={{ backgroundColor: '#fff',marginBottom: 180 }}>
          {/* metadata loding */}
          <MyLoader active={isloading} />
          <View style={{ padding: 10, paddingLeft: 20, backgroundColor: Colors.background }}>
            <Text style={{ fontSize: fontType('Heading'), fontFamily: "Inter", fontWeight: '600' }}>
              {fromEdit ? 'Edit Profile' : 'Subject Information'}
            </Text>


          </View>
          <Formik
            initialValues={this.setInitialValues()}
            validationSchema={this.getValidation()}
            onSubmit={(values, formikActions) => {
              this.onSubmit(values);
              setTimeout(() => {
                formikActions.setSubmitting(false)
              }, 500);
            }}>
            {props => (
              <View style={{ padding: 3,flex:1 ,height:'100%'}}>

                {/* ------------- firstName --------------------- */}
                <View style={styles.fields}>
                  <View style={styles.lables}>
                    <Text style={styles.textLable}>{this.getMandatoryIcon('red')}First Name :</Text>
                  </View>
                  <View style={styles.iptView}>
                    <TextInput
                      placeholder={'Enter First Name'}
                      // style={{ fontSize: fontType('BasicFont'), color: Colors.text, fontFamily: 'Inter', borderWidth: 1, borderColor: 'grey', backgroundColor: 'white', padding: 5 }}
                      onChangeText={props.handleChange('firstName')}
                      value={props.values.firstName}
                      maxLength={100}
                      style={ constStyles.inputStyles}
                    />
                    {props.errors.firstName && props.touched.firstName && (
                      <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.firstName}</Text>
                    )}

                  </View>
                </View>

                {/* ------------- middleName --------------------- */}
                <View style={styles.fields}>
                  <View style={styles.lables}>
                    <Text style={styles.textLable}>Middle Name :</Text>
                  </View>
                  <View style={styles.iptView}>
                    <TextInput
                      // style={{ fontSize: fontType('BasicFont'), color: Colors.text, fontFamily: 'Inter', borderWidth: 1, borderColor: 'grey', backgroundColor: 'white', padding: 5 }}
                      onChangeText={props.handleChange('middleName')}
                      value={props.values.middleName}
                      placeholder={'Enter Middle Name'}
                      maxLength={100}
                      style={ constStyles.inputStyles}
                    />
                    {props.errors.middleName && props.touched.middleName && (
                      <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.middleName}</Text>
                    )}

                  </View>
                </View>

                {/* ------------- lastName --------------------- */}
                <View style={styles.fields}>
                  <View style={styles.lables}>
                    <Text style={styles.textLable}>{this.getMandatoryIcon('red')}Last Name :</Text>
                  </View>
                  <View style={styles.iptView}>
                    <TextInput
                      // style={{ fontSize: fontType('BasicFont'), color: Colors.text, fontFamily: 'Inter', borderWidth: 1, borderColor: 'grey', backgroundColor: 'white', padding: 5 }}
                      onChangeText={props.handleChange('lastName')}
                      value={props.values.lastName}
                      placeholder={'Enter Last Name'}
                      maxLength={100}
                      style={ constStyles.inputStyles}
                    />
                    {props.errors.lastName && props.touched.lastName && (
                      <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.lastName}</Text>
                    )}

                  </View>
                </View>

                {/* ------------- DatePicker(using different datepicker for web and android) --------------------- */}
                {/* <View style={[styles.fields, { zIndex:2 }]}>
                  <View style={styles.lables}>
                    <Text style={styles.textLable}>Date of Birth :</Text>
                  </View>
                  <View style={styles.iptView}> */}

                    {/* Date picker for web */}
                    {/* <DatePk
                      changeDateValidation={this.changeDateValidation}
                      changeDate={(value) => {

                        this.changeDate(value, props.setFieldValue);

                      }} date={props.values.dob} t={t} /> */}

                    {/* Date picker for tablet */}
                    {/* <DatePicker
                      style={{ width: SCREEN_WIDTH >= 1024 ? wp("40%") : wp("50%")
                      ,paddingTop:15 }}
                      date={this.state.date}
                      mode="date"
                      placeholder={t('DateOfBirth')}
                      format="DD-MMM-YYYY"
                      minDate="01-01-1900"
                      confirmBtnText={t('Confirm')}
                      cancelBtnText={t('Cancel')}
                      customStyles={{
                        placeholderText: {
                          fontSize: fontType('BasicFont'),
                          fontFamily: 'Inter',
                          marginLeft: 0,
                          bottom: 9,
                          left: 0,
                        },
                        dateText: {
                          bottom: 0,
                          fontSize: fontType('BasicFont'),
                          fontFamily: 'Inter'
                        },
                        dateIcon: {
                          position: 'absolute',
                          right: 0,
                          bottom: 5,
                          resizeMode: 'contain',
                          height: 50,
                          width: 50
                        },
                        dateInput: {
                          height: hp('4%'),
                          alignItems: 'flex-start',
                          marginLeft: wp('1%'),
                          borderTopColor: 'white',
                          borderStartColor: 'white',
                          borderEndColor: 'white',

                          borderColor: '#cacaca'
                        },
                      }}

                      onDateChange={(date) => {
                        this.changeDateValidation();
                        this.changeDate(date, props.setFieldValue);
                      }}
                    />   */}

                    {/* {props.errors.dob && props.touched.dob && (
                      <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.dob}</Text>
                    )}

                  </View>
                </View> */}

                {/* ------------- phoneNo --------------------- */}
                {/* <View style={[styles.fields, { zIndex: -2 }]}>
                  <View style={styles.lables}>
                    <Text style={styles.textLable}>Phone Number :</Text>
                  </View>
                  <View style={styles.iptView}>
                    <PhoneNumberInput
                      phoneNumber={props.values.phoneNo}
                      getPhoneNumber={(label, value) => {
                        props.setFieldValue(label, value);
                      }}
                    />
                    {props.errors.phoneNo && props.touched.phoneNo && (
                      <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.phoneNo}</Text>
                    )}
                  </View>
                </View> */}

                {/* ------------- Email --------------------- */}
                <View style={[styles.fields, { zIndex: -2 }]}>
                  <View style={styles.lables}>
                    <Text style={styles.textLable}>Email :</Text>
                  </View>
                  <View style={styles.iptView}>
                    <TextInput
                      // style={{ fontSize: fontType('BasicFont'), color: Colors.text, fontFamily: 'Inter', borderWidth: 1, borderColor: 'grey', backgroundColor: 'white', padding: 5 }}
                      onChangeText={props.handleChange('email')}
                      value={props.values.email}
                      placeholder={'Enter Email'}
                      maxLength={100}
                      style={ constStyles.inputStyles}
                    />
                    {props.errors.email && props.touched.email ? (
                      <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.email}</Text>
                    ) : (
                      <Text style={{ color: 'black', fontSize: fontType('subFont') }}>Email is necessary for Remote Consent.</Text>
                    )}

                  </View>
                </View>

                {/* ------------- Language --------------------- */}
                <View style={[styles.fields, { zIndex:1 }, ]}>
                  <View style={styles.lables}>
                    <Text style={styles.textLable}>{this.getMandatoryIcon('red')}Consent Language :</Text>
                  </View>
                  <View style={[styles.iptView, {flexDirection:'column'}]}>
                    {/* <TouchableOpacity
                      // style={Platform.OS == 'web' ? { padding: 5, border: '1px solid gray' } : { padding: 5, border: 2, borderColor: 'black', borderColor: 'gray', borderWidth: 1 }}
                      onPress={() => this.setState({ showLanguagesPopup: true })}
                      style={constStyles.inputStyles}
                    > */}
                      <LanguageSelection
                        items={items}
                        onItemSelect={(item) => {
                          props.setFieldValue('locale', item.name);
                          this.setState({ LanguageValue: item.name, LanguageId: item.id, showLanguagesPopup: false });

                        }}
                        selectedValue={this.state.LanguageValue}
                        
                      />
                      {/* <Text 
                      style={{ color: LanguageValue === 'Select' ? 'gray' : Colors.text, fontSize: fontType('BasicFont') }}
                      >
                        {this.state.LanguageValue}
                      </Text> */}
                    {/* </TouchableOpacity> */}
                   
                    

                    {props.errors.locale && (
                      <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.locale}</Text>
                    )}
                  </View>

                  <View>
                    <Dialog
                      containerStyle={{ justifyContent: 'center' }}
                      rounded={true}
                      visible={this.state.showLanguagesPopup}
                      width={SCREEN_WIDTH >= 1024 ? '30%' : '50%'}
                    >
                      <DialogContent>
                        <View style={{ padding: 5 }}>
                          <FlatList
                            data={languages}
                            keyExtractor={item => item.id}
                            renderItem={({ item }) =>
                              <ListItem thumbnail onPress={() => {
                                props.setFieldValue('locale', item.languageName);
                                this.setState({ LanguageValue: item.languageName, showLanguagesPopup: false });
                              }}>
                                <Body>
                                  <ScrollView>
                                    <View>
                                      <Text style={{ fontSize: fontType('BasicFont'), fontFamily: 'Inter' }}>
                                        {item.languageName}
                                      </Text>
                                    </View>
                                  </ScrollView>
                                </Body>
                              </ListItem>}
                          />
                        </View>
                      </DialogContent>
                    </Dialog>
                  </View>
                  <View>
                  </View>

                </View>
                {data.lastConsentedIrbVersion ?
                  <View style={[styles.fields, { zIndex: -2 }]}>
                    <View style={styles.lables}>
                      <Text style={styles.textLable}>Last Consented IRB Version :</Text>
                    </View>
                    <Text style={{ fontSize: fontType('BasicFont'), paddingTop: 4, color: Colors.text, fontFamily: 'Inter', }}>{data.lastConsentedIrbVersion}</Text>
                  </View> : null}
                {data.consentedOn ?
                  <View style={[styles.fields, { zIndex: -2 }]}>
                    <View style={styles.lables}>
                      <Text style={styles.textLable}>Last Consented On :</Text>
                    </View>
                    <Text style={{ fontSize: fontType('BasicFont'), paddingTop: 4, color: Colors.text, fontFamily: 'Inter', }}>{formatDate(data.consentedOn, data.originatingTimezone)}</Text>
                  </View> : null}

                {/* -------------uSubjectId---------- */}
                {fromEdit ? <View style={[styles.fields, { zIndex: -2 }]}>
                  <View style={styles.lables}>
                    <Text style={styles.textLable}>Subject ID :</Text>
                  </View>
                  <View style={styles.iptView}>
                    <TextInput
                      placeholder={'Enter Subject ID'}
                      // style={{ fontSize: fontType('BasicFont'), color: Colors.text, fontFamily: 'Inter', borderWidth: 1, borderColor: 'grey', backgroundColor: 'white', padding: 5 }}
                      onChangeText={props.handleChange('usubjectId')}
                      value={props.values.usubjectId}
                      maxLength={100}
                      style={ constStyles.inputStyles}
                    />
                    {props.errors.usubjectId && props.touched.usubjectId && (
                      <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.usubjectId}</Text>
                    )}

                  </View>
                </View> : <View />}
                {/* -------------Status------------ */}
                {fromEdit ? <View style={[styles.fields, { zIndex: -2 }]}>
                  <View style={styles.lables}>
                    <Text style={styles.textLable}>Subject Status</Text>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                    <Text
                      style={{
                        fontSize: fontType('subFont'),
                        fontFamily: 'Inter',
                        alignSelf: 'center', color: Colors.noOfSubjects,
                        // padding: 6, paddingBottom: 20
                      }}>
                      {this.renderIcons(data.status)}
                    </Text>
                    <Text style={{ fontSize: fontType('subFont') }}>
                      &nbsp;{renderStatusName(data.status)}
                    </Text>
                  </View>
                </View> : <View />}

                {/* ------------- LAR Collapsable Tab --------------------- */}
                <View style={{ marginTop: 10,zIndex:-10 }}>
                  <TouchableOpacity style={{ backgroundColor: '#d2d4d6' }} onPress={() => { 
                    
                    this.setLarValues(props.setFieldValue,props.values.larAsWitness); 
                    // if(!larVisible){
                     
                    // props.setFieldError( 'larFirstName', ' ');
                    // props.setFieldError( 'larLastName', ' ');
                    // props.setFieldError( 'larEmail', ' ');
                    // if(props.values.larAsWitness){
                     
                    // }
                    // }
                    // if(witnessVisible){
                      // props.setFieldError( 'witnessFirstName', ' ');
                      // props.setFieldError( 'witnessLastName', ' ');
                      // props.setFieldError( 'witnessEmail', ' ');
                    // }
                   } }>
                    <Text style={{ padding: 5, fontSize: fontType('Heading'), fontFamily: "Inter",fontWeight: '600' }}>
                      {!larVisible ? '+  ' : '-  '}
                          Legally Authorized Representative
                            </Text>
                  </TouchableOpacity>
                  <Collapsible collapsed={!larVisible} >

                    {/* ------------- LAR firstName --------------------- */}
                    <View style={styles.fields}>
                      <View style={styles.lables}>
                        <Text style={styles.textLable}>{this.getMandatoryIcon('red')}First Name :</Text>
                      </View>
                      <View style={styles.iptView}>
                        <TextInput
                          placeholder={'Enter First Name'}
                          // style={{ fontSize: fontType('BasicFont'), color: Colors.text, fontFamily: 'Inter', borderWidth: 1, borderColor: 'grey', backgroundColor: 'white', padding: 5 }}
                          onChangeText={props.handleChange('larFirstName')}
                          onChange = {(event) => {    
                            const { text } = event.nativeEvent;
                            if(props.values.larAsWitness) {
                              this.setWitnessOnChangeValues(props.setFieldValue,text,'witnessFirstName');
                            }
                          }}
                          value={props.values.larFirstName}
                          maxLength={100}
                          style={ constStyles.inputStyles}
                        />
                        {props.errors.larFirstName && props.touched.larFirstName && (
                          <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.larFirstName}</Text>
                        ) }
                      </View>
                    </View>

                    {/* ------------- LAR lastName --------------------- */}
                    <View style={styles.fields}>
                      <View style={styles.lables}>
                        <Text style={styles.textLable}>{this.getMandatoryIcon('red')}Last Name :</Text>
                      </View>
                      <View style={styles.iptView}>
                        <TextInput
                          // style={{ fontSize: fontType('BasicFont'), color: Colors.text, fontFamily: 'Inter', borderWidth: 1, borderColor: 'grey', backgroundColor: 'white', padding: 5 }}
                          onChangeText={props.handleChange('larLastName')}
                          onChange = {(event) => {    
                            const { text } = event.nativeEvent;
                            if(props.values.larAsWitness) {
                              this.setWitnessOnChangeValues(props.setFieldValue,text,'witnessLastName');
                            }
                          }}
                          value={props.values.larLastName}
                          placeholder={'Enter Last Name'}
                          maxLength={100}
                          style={ constStyles.inputStyles}
                        />
                        {props.errors.larLastName && props.touched.larLastName && (
                          <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.larLastName}</Text>
                        )}
                      </View>
                    </View>

                    {/* ------------- LAR Email --------------------- */}
                    <View style={[styles.fields, { zIndex: -2 }]}>
                      <View style={styles.lables}>
                        <Text style={styles.textLable}>Email :</Text>
                      </View>
                      <View style={styles.iptView}>
                        <TextInput
                          // style={{ fontSize: fontType('BasicFont'), color: Colors.text, fontFamily: 'Inter', borderWidth: 1, borderColor: 'grey', backgroundColor: 'white', padding: 5 }}
                          onChangeText={props.handleChange('larEmail')}
                          onChange = {(event) => {    
                              const { text } = event.nativeEvent;
                              if(props.values.larAsWitness) {
                                this.setWitnessOnChangeValues(props.setFieldValue,text,'witnessEmail');
                              }
                          }}
                          value={props.values.larEmail}
                          placeholder={'Enter Email'}
                          maxLength={100}
                          style={ constStyles.inputStyles}
                        />
                        {props.errors.larEmail && props.touched.larEmail ? (
                          <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.larEmail}</Text>
                        ) : (
                          <Text style={{ color: 'black', fontSize: fontType('subFont') }}>Email is necessary for Remote Consent.</Text>
                        )}
                      </View>
                    </View>

                    {/* ------------- LAR Relation --------------------- */}
                    <View style={styles.fields}>
                      <View style={styles.lables}>
                        <Text style={styles.textLable}>Relation :</Text>
                      </View>
                      <View style={styles.iptView}>
                        <TextInput
                          // style={{ fontSize: fontType('BasicFont'), color: Colors.text, fontFamily: 'Inter', borderWidth: 1, borderColor: 'grey', backgroundColor: 'white', padding: 5 }}
                          onChangeText={props.handleChange('larRelation')}
                          // onChange = {(event) => {    
                          //   const { text } = event.nativeEvent;
                          //   if(props.values.larAsWitness) {
                          //     // this.setWitnessOnChangeValues(props.setFieldValue,text,'witnessLastName');
                          //   }
                          // }}
                          value={props.values.larRelation}
                          placeholder={'Enter Relationship'}
                          maxLength={50}
                          style={ constStyles.inputStyles}
                        />
                      </View>
                    </View>

                    {/* ------------- checkbox --------------------- */}
                    <View style={styles.fields}>
                      <View style={[styles.lables, { alignItems: 'flex-end', borderColor: '#c9c9c9' }]}>
                        <RadioButton
                          isSelected={props.values.larAsWitness}
                          isCurrentFieldDisabled={false}
                          onOptionSelected={()=>{
                            props.setFieldValue('larAsWitness',!props.values.larAsWitness)
                            this.setState({
                              isLarWitness: !props.values.larAsWitness,
                              witnessVisible: true
                            },()=> {
                  
                            const larFirstName = props.getFieldProps('larFirstName').value;
                            const larLastName = props.getFieldProps('larLastName').value;
                            const larEmail = props.getFieldProps('larEmail').value;
        
                            this.setWitnessValues(larFirstName,larLastName,larEmail,props.setFieldValue);
        
                              
                            })
                            
                          }}
                        />
                      </View>
                      <View style={[styles.iptView, { marginLeft: 15}]}>
                        <Text style = {{ fontFamily: 'Inter'}}>LAR acts as witness.</Text>
                        {props.errors.lastName && props.touched.lastName && (
                          <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.middleName}</Text>
                        )}
                      </View>
                    </View>
                  </Collapsible>
                </View>

                {/* ------------- WITNESS Callopsable Tab --------------------- */}
                <View style={{ marginTop: 10,zIndex:-10 }}>
                  <TouchableOpacity style={{ backgroundColor: '#d2d4d6' }} onPress={() => {
                      this.setWitnessValuesOnTouch(props.setFieldValue)
                      // props.setFieldError( 'witnessFirstName', ' ');
                      // props.setFieldError( 'witnessLastName', ' ');
                      // props.setFieldError( 'witnessEmail', ' ');
                    if(props.values.larAsWitness){
                      props.setFieldValue('larAsWitness', false);
                    }
                    this.setState({
                      witnessVisible: !witnessVisible
                    });
                    
                      
                    
                  }}
                  >
                    <Text style={{ padding: 5, fontSize: fontType('Heading'), fontFamily: "Inter", fontWeight: '600' }}>
                      {!witnessVisible ? '+  ' : '-  '}
                              Witness
                            </Text>
                  </TouchableOpacity>
                  <Collapsible collapsed={!witnessVisible} >

                    {/* ------------- WITNESS firstName --------------------- */}
                    <View style={styles.fields}>
                      <View style={styles.lables}>
                        <Text style={styles.textLable}>{this.getMandatoryIcon('red')}First Name :</Text>
                      </View>
                      <View style={styles.iptView}>
                        <TextInput
                          editable={!props.values.larAsWitness}
                          placeholder={'Enter First Name'}
                          // style={{ fontSize: fontType('BasicFont'), color: Colors.text, fontFamily: 'Inter', borderWidth: 1, borderColor: 'grey', backgroundColor: 'white', padding: 5 }}
                          onChangeText={props.handleChange('witnessFirstName')}
                          value={props.values.witnessFirstName}
                          maxLength={100}
                          style={ constStyles.inputStyles}
                        />
                        {!props.values.larAsWitness && props.errors.witnessFirstName && props.touched.witnessFirstName && (
                          <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.witnessFirstName}</Text>
                        )}
                      </View>
                    </View>

                    {/* ------------- WITNESS lastName --------------------- */}
                    <View style={styles.fields}>
                      <View style={styles.lables}>
                        <Text style={styles.textLable}>{this.getMandatoryIcon('red')}Last Name :</Text>
                      </View>
                      <View style={styles.iptView}>
                        <TextInput
                          editable={!props.values.larAsWitness}
                          // style={{ fontSize: fontType('BasicFont'), color: Colors.text, fontFamily: 'Inter', borderWidth: 1, borderColor: 'grey', backgroundColor: 'white', padding: 5 }}
                          onChangeText={props.handleChange('witnessLastName')}
                          value={props.values.witnessLastName}
                          placeholder={'Enter Last Name'}
                          maxLength={100}
                          style={ constStyles.inputStyles}
                        />
                        {!props.values.larAsWitness && props.errors.witnessLastName && props.touched.witnessLastName && (
                          <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.witnessLastName}</Text>
                        )}
                      </View>
                    </View>

                    {/* ------------- WITNESS Email --------------------- */}
                    <View style={[styles.fields, { zIndex: -2 }]}>
                      <View style={styles.lables}>
                        <Text style={styles.textLable}>Email :</Text>
                      </View>
                      <View style={styles.iptView}>
                        <TextInput
                          editable={!props.values.larAsWitness}
                          // style={{ fontSize: fontType('BasicFont'), color: Colors.text, fontFamily: 'Inter', borderWidth: 1, borderColor: 'grey', backgroundColor: 'white', padding: 5 }}
                          onChangeText={props.handleChange('witnessEmail')}
                          value={props.values.witnessEmail}
                          placeholder={'Enter Email'}
                          maxLength={100}
                          style={constStyles.inputStyles}
                        />
                        {!props.values.larAsWitness && props.errors.witnessEmail && props.touched.witnessEmail ? (
                          <Text style={{ color: 'red', fontSize: fontType('subFont') }}>{props.errors.witnessEmail}</Text>
                        ) : (
                          <Text style={{ color: 'black', fontSize: fontType('subFont') }}>Email is necessary for Remote Consent.</Text>
                        )}

                      </View>
                    </View>

                  </Collapsible>
                  
                </View>

                {/* ------------- Submit button --------------------- */}
                <View style={ { justifyContent:'center',flexDirection: "row",flexGrow : 1,
    marginVertical: 4,
    padding: 2,
    alignItems: 'center' }}>
                <TouchableOpacity
                  onPress={props.handleSubmit}
                  style={[constStyles.buttonStyle, { marginTop: 10,marginBottom:20 }]}
                  disabled={isloading}
                >
                  <Text style={[constStyles.buttonTxt, { marginHorizontal: 50 }]} >
                    SAVE
                          </Text>
                </TouchableOpacity>
                </View>
                <SubjectDetailsReasonForChange 
                  showReasonForChangeModal={showReasonForChangeModal} 
                  screenProps={screenProps} 
                  onReasonForChangeSave={(reason) => this.onReasonForChangeSave(reason, props.values)}
                  setModalVisible={() => this.setState({showReasonForChangeModal: false})}
                  oldObj = {data}
                  newObj = {props.values}
                  loading={isloading}
                  getValueOfField = {(key, val) => this.getValueOfField(key, val)}
                />
              </View>  
            )}
          </Formik>
        </Container>
      </ScrollView>
      </View>
    )
  }
}


export default AddSubject;

const styles = StyleSheet.create({
  textLable: {
    fontSize: fontType('BasicFont'),
    // color: Colors.coloredText,
    fontFamily: 'Inter',
    padding: 6,
    textAlign: 'right',
  },
  sbmtBtn: {
    alignSelf: 'baseline',
    minWidth: wp('26%'),
    backgroundColor: Colors.coloredText,
    borderWidth: 1,
    // borderColor:Colors.coloredText, 
    marginTop: wp('6%'),
    marginLeft: wp('30%'),
    borderRadius: 6,
    justifyContent: 'center',
  },
  fields: {
    flexDirection: "row",
    marginVertical: 4,
    padding: 2,
    alignItems: 'center'

  },
  btnTxt: {
    // marginLeft:wp('8%'),
    color: Colors.textColorwithbg,
    fontSize: wp('2.6%'),
    fontFamily: 'Inter_Bold',
  },
  lables: {
    width: '30%',
    justifyContent: 'center'
  },
  iptView: {
    width: SCREEN_WIDTH >= 1024 ? "40%" : "50%",
    color: '#c9c9c9',
  }
});


