import React, { Component } from "react";
import {
  View,
  Dimensions,
  Text,
  Modal,
  Platform,
  TouchableOpacity,
  Keyboard,
  TouchableWithoutFeedback
} from "react-native";
import { Icon, Footer, Container, } from "native-base";
import QuestionScreen from "./QuestionScreen";
import { createForm } from "rc-form";
import { validateAnswer } from './utils';
import {styles} from './styles/knowledgeReviewStyles'
import HelpWindow from './HelpWindow';
import Dialog, { DialogContent, DialogTitle, ScaleAnimation } from "react-native-popup-dialog";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import ChapterTitle from '../chapterTitle/ChapterTitle';
import { Colors } from "../../constants/ui/colorScheme";
import {fontType} from '../../styles/font';
import {chapterButtonStyles } from '../../styles/constStyles';
import {
  MaterialIcons,
} from '@expo/vector-icons';
import NoDataPage from '../emptyData/NoDataPage';
import api from '../../utils/api';
import showToast from '../../utils/toast';
import MyLoader from '../../utils/webLoader';
import BottomSheet from '../common/bottomSheet/bottomSheet';
import CommentsPage from '../comments/commentsContainer';
import { ChapterType } from "../common/constants";
import constants from "../../utils/constants.js";
const { ContextProperties } = constants


const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')

const childRef = React.createRef();
class KnowledgeReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOrdinal: 1,
      attempts: 2,
      hintLableVisible: false,
      showHintModalVisible:false,
      chapterTitle:'Knowledge Review',
      showWrongIcon: false,
      loading: false,
      scrollRef: null,
      openComments: false,
    };
    this.userQuizAnswers = []
  }

  componentDidUpdate(prevProps, prevState) {
    const {scrollRef} = this.state;
    if( scrollRef != null && scrollRef._root != null &&  prevState.scrollRef != scrollRef) {
      scrollRef._root.scrollToPosition(0, 0, false);
    }
  }

  setScrollRef = (ref) => {
    const {scrollRef} = this.state;
    if(scrollRef == null) {
        this.setState({
            scrollRef: ref,
        });
    }
  }

  changeCurrentOrdinal = () => {
    const { currentOrdinal } = this.state;
    const {knowledgeReview} = this.props;
    if (knowledgeReview.quizItems.length === currentOrdinal) {
      return this.handleSubmit();
    }
    else {
      this.setState(
        {
          currentOrdinal: currentOrdinal + 1,
          attempts: 2,
          hintLableVisible: false,
      })
    }
  }

  getCurrentField = () => {
    const {knowledgeReview} = this.props;
    const { currentOrdinal } = this.state;
    const currentField = knowledgeReview.quizItems.filter(field => field.ordinal === currentOrdinal)[0];
    return currentField;
  }

  setResult = (fieldType, values, currentField) => {
    if (fieldType === 'txt' || fieldType === 'txtarea' || fieldType ==='num') {
      return validateAnswer(currentField, values[currentField.id].value);
    }
    else {
      return currentField.answer === currentField.options[values[currentField.id].value];
    }
  }

  nextField = () => {
    const { form } = this.props;
    const {attempts, scrollRef} = this.state;
    const currentField = this.getCurrentField();
    form.validateFields([currentField.id], (error, values) => {
      if (error) {
        console.log(error);
      }
      else {
        let isCorrectAnswer = validateAnswer(currentField, values[currentField.id].value);
        const currentAttempt = 3 - attempts 
        const fieldType = currentField.answerType;
        if (isCorrectAnswer) {
          const attemptKey = `${currentField.id}.attempts`
          form.setFieldsValue({
            [attemptKey]: currentAttempt
          })
          const question={
            quizItem: {
              id: currentField.id,
            },
            attemptNo: currentAttempt,
            answer: (fieldType === 'txt' || fieldType === 'txtarea' || fieldType ==='num') ? values[currentField.id].value : currentField.options[values[currentField.id].value],    
            result: this.setResult(fieldType, values, currentField),
          }
          this.userQuizAnswers.push(question);
          this.changeCurrentOrdinal();
          if( scrollRef != null && scrollRef._root != null) {
            scrollRef._root.scrollToPosition(0, 0, false);
          }
        }  
        else {
        this.handleWrongIcon(true);
          this.setState(prevState => ({
            ...prevState,
            attempts: prevState.attempts - 1 <= 0 ? 0 : prevState.attempts - 1,
            hintLableVisible:true,
          }));
          const attemptKey = `${currentField.id}.attempts`
          form.setFieldsValue({
            [attemptKey]: 3 - attempts 
          })
          if(attempts === 0) {
            const question={
              quizItem: {
                id: currentField.id,
              },
              attemptNo: currentAttempt,
              answer: (fieldType === 'txt' || fieldType === 'txtarea' || fieldType ==='num') ? values[currentField.id].value : currentField.options[values[currentField.id].value],    
              result: this.setResult(fieldType, values, currentField),
            }
            this.userQuizAnswers.push(question);
            this.changeCurrentOrdinal();
            this.setState(prevState => ({
              ...prevState,
              hintLableVisible:false,
            }));
          }
        }
      }
    });
  };

  changeAttemptsBasedOnOrdinal = () => {
    const {currentOrdinal} = this.state;
    const currentField = this.getCurrentField();
    if (currentField.ordinal === currentOrdinal) {
      this.setState({
        attempts: 1,
      })
    }
  }

  handleSubmit = () => {
    const { isRegistered, form, storeQuizAttemptData, subjectData, navigation, knowledgeReview: { quizItems }, screenProps: { t } } = this.props;
    const currChapterOrdinal =  navigation.getParam('ordinal');
    form.validateFields(async(error, values) => {
      if (error) {
        console.log(error);
      } else {
        if (isRegistered) {
          this.setState({loading:true});
          try {
            const subject = {
              id: subjectData.id,
              subjectConsent : {
                id: subjectData.subjectConsentId,
                isKrDone: true,
                quizAttempts: this.userQuizAnswers,
              }
            }
            const res = await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subjectData.id}/subjectConsents/${subjectData.subjectConsentId}/knowledgeReviews`
              ,subject);
            this.setState({loading:false});

          }catch(error) {
            this.setState({loading:false});
            console.log(error);
            if (error.response) {
              // showToast("Something went wrong." `t('Question'), 'danger', 4000);
              showToast(t('StopPartFailed'), 'danger', 2000);
            } else {
              showToast(t('NetworkErr'), 'danger', 2000);
            }
          }
  

        }
        storeQuizAttemptData(this.userQuizAnswers)
        // navigation.navigate('CongratulationsScreen', {currChapterOrdinal: currChapterOrdinal});
        navigation.navigate('CommentsReviewScreen', {currChapterOrdinal: currChapterOrdinal});
      }
    })
  }

  handleHintLabelVisibility = () => {
    this.setState(prevState => ({
      ...prevState,
      hintLableVisible: !prevState.hintLableVisible,
    }));
  }

  handleShowHintModalVisibility = () => {
    this.setState(prevState => ({
      ...prevState,
      showHintModalVisible: !prevState.showHintModalVisible
    }));
  }
  
  handleWrongIcon = (showWrongIcon) => {
    this.setState(prevState => ({
      ...prevState,
      showWrongIcon: showWrongIcon
    }));
  }

  onCommentClick = () => {
    if(this.state.openComments && childRef.current){
      childRef.current.onAdd();
    }
    this.setState(prevState => ({
        ...prevState,
        openComments: true
    }))
  }

  onCommentCancelClick = () => {
    this.setState(prevState => ({
        ...prevState,
        openComments: false
    }))
  }

  render() {
    const { form, knowledgeReview , navigation, isRegistered, subjectData, screenProps: { t }} = this.props;
    const { currentOrdinal, hintLableVisible, attempts, userAnswers, showWrongIcon, loading, scrollRef, openComments } = this.state;
    const currChapterOrdinal =  navigation.getParam('ordinal');
    const {chapterTitle} = knowledgeReview;
    if(knowledgeReview.quizItems.length > 0) {
      return (
        <Container>
        <MyLoader  active={loading}/>
          <ChapterTitle chapterType="KNOWLEDGE_REVIEW" chapterTitle={chapterTitle} onCommentClick = {this.onCommentClick}/>
        <View style={{ flex:1}} >
          {/* Current Question Number */}
          <View style={styles.currentQuestionNumber}>
            <Text style={{ fontSize: fontType('BasicFont'), color: Colors.text, alignContent:'flex-start', marginLeft:10, fontFamily:'Inter'}}>
              {t('Question')} {currentOrdinal} of {knowledgeReview.quizItems.length}
            </Text>
          </View>
          {/* Render Question & Field */}
          <View style={{ flex: 5.3, flexWrap:'wrap'}}>
            <QuestionScreen
              hintLableVisible={hintLableVisible}
              form={form}
              knowledgeReview={knowledgeReview}
              attempts={attempts}
              userAnswers={userAnswers}
              currentOrdinal={currentOrdinal}
              handleShowHintModalVisibility={this.handleShowHintModalVisibility}
              handleWrongIcon={this.handleWrongIcon}
              showWrongIcon={showWrongIcon}
              t={t}
              scrollRef={scrollRef}
              setScrollRef={this.setScrollRef}
              getCurrentField={this.getCurrentField}
            />
          </View>
          {/* Show Hint */}
          {Platform.OS == 'web' ?
              <Dialog
                containerStyle={{ justifyContent: 'space-evenly', flex: 1, }}
                visible={this.state.showHintModalVisible}
                height={1}
                rounded={false}
              >
                  <DialogContent style={{backgroundColor:'#f9f9f9', flex:1}}>
                  <View style={SCREEN_WIDTH >= 1030 ? { width:800, height:'100%',}:{ width: Platform.OS == 'web' ? SCREEN_WIDTH : '100%', padding: 10 }}>
                      <View style={{ height:SCREEN_HEIGHT-100 }}>
                          <View style={{ flexDirection: 'row', padding:10, marginTop:5 }}>
                            <View style={{ flex: 5.5 }}>
                              <Text style={{ fontSize: fontType('BasicFont'), color: Colors.coloredText, marginTop: 5, marginLeft: 5, fontFamily: 'Inter' }}>
                                {t('Help')}
                              </Text>
                            </View>
                            <TouchableOpacity style={{ flex: 0.5 }} onPress={() => this.handleShowHintModalVisibility()}>
                              <Text style={{ marginRight: wp('1%') }}>
                                <MaterialIcons name="cancel" color={Colors.discontinued} size={fontType('StatusIcon')} />
                              </Text>
                            </TouchableOpacity>
                          </View>
                        <View style={{flexDirection:'row', padding:10, marginTop:5}}>
                          <Text style={styles.text}>{this.getCurrentField().hint}</Text>
                        </View>
                      </View>
                      <View style={{height:100, justifyContent:'center'}}>
                          
                          <TouchableOpacity
                            style={{
                              height: 45,
                              backgroundColor: Colors.buttondisabled,
                              justifyContent: 'center',
                            }}
                            onPress={() => this.handleShowHintModalVisibility()}
                          >
                            <Text style={{ alignSelf: 'center', fontSize: fontType('BasicFont'), color: Colors.textColorwithbg, fontFamily: 'Inter_Bold' }}>
                              {t('Close')}
                            </Text>
                          </TouchableOpacity>
                      </View>
                  </View>

                  </DialogContent>
              </Dialog>
              :
            <View>
              {Platform.OS !== 'web'&& 
            <Modal
            style={{width: 250, height:100}}
            animationType="slide"
            supportedOritentions
            transparent={true}
            visible={Platform.OS !== 'web' && this.state.showHintModalVisible}
            onBackdropPress={() => {
              this.handleShowHintModalVisibility()
            }}
            onRequestClose={() => {
              this.handleShowHintModalVisibility()
            }}>
          <HelpWindow handleShowHintModalVisibility={this.handleShowHintModalVisibility}
                      attempts={attempts} 
                      getCurrentField={this.getCurrentField}
                      t={t}
          />
          </Modal>}
          </View>
          }
            {/* Footer */}
            <View>
              <BottomSheet open={openComments} onClose = {() => this.setState(prevState => ({ ...prevState, openComments: false}))}>
                <CommentsPage ref={childRef} addNewComment = {true} onCommentCancelClick={this.onCommentCancelClick} chapterType={ChapterType.KNOWLEDGE_REVIEW.key}/>
              </BottomSheet>
          </View>
            <Footer style={{justifyContent: 'flex-end', backgroundColor: 'rgb(145, 85, 253)'}}>
                <TouchableOpacity  onPress={() => { this.nextField() }}
                  style={chapterButtonStyles.button}>
                  <Text style={chapterButtonStyles.buttonTxt}>{t('Continue')}</Text>
                </TouchableOpacity> 
            </Footer>
        </View>
        </Container>
    )} else {
      return (
        <Container>
          <ChapterTitle chapterType="KNOWLEDGE_REVIEW" chapterTitle={chapterTitle} onCommentClick={this.onCommentClick} />
          <NoDataPage message={t('NoData')} />
          <View>
            <BottomSheet open={openComments} onClose = {() => this.setState(prevState => ({ ...prevState, openComments: false}))}>
                <CommentsPage ref={childRef} addNewComment = {true} onCommentCancelClick={this.onCommentCancelClick} chapterType={ChapterType.KNOWLEDGE_REVIEW.key}/>
            </BottomSheet>
          </View>
          <Footer style={{ justifyContent: 'flex-end', backgroundColor: 'rgb(145, 85, 253)' }}>
            <TouchableOpacity 
                  // onPress={() => { navigation.navigate('CongratulationsScreen', {currChapterOrdinal: currChapterOrdinal}); }}
                  onPress={() => { this.handleSubmit() }}
              style={chapterButtonStyles.button}>
              <Text style={chapterButtonStyles.buttonTxt}>{t('Continue')}</Text>
            </TouchableOpacity>
          </Footer>
        </Container>
      )
    }
  }
}
export default createForm()(KnowledgeReview);


