import React, { Component } from "react";
import { View, Text, TouchableOpacity, Dimensions, Image } from "react-native";
import { Footer, Container, Content, Icon } from "native-base";
import ChapterPage from "./ChapterPage";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import ChapterTitle from "../chapterTitle/ChapterTitle";
import {
  prevButtonVisible,
  onSelectingPrevChapter,
  onSelectingNextChapter,
} from "../../utils/consentData";
import { Colors } from "../../constants/ui/colorScheme";
import { NavigationEvents } from "react-navigation";
import moment from "moment-timezone";
import _ from "lodash";
import {
  getOverallTimeSpentBySubject,
  getSubjectPageExitTime,
  buildSubjectNavTour,
} from "../../utils/subjectNavTourUtils";
import CheckBox from "react-native-check-box";
import { ConsentDataContext } from "../../providers/ConsentDataProvider";
import NoDataPage from "../emptyData/NoDataPage";
import { MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons";
import { fontType } from "../../styles/font";
import { chapterButtonStyles } from "../../styles/constStyles";
import { fromBinary } from "uuid-js";
import api from "../../utils/api";
import showToast from "../../utils/toast";
import MyLoader from "../../utils/webLoader";
import BottomSheet from "../common/bottomSheet/bottomSheet";
import CommentsPage from "../comments/commentsContainer";
import { ChapterType } from "../common/constants";
import constants from "../../utils/constants.js";
const { ContextProperties } = constants

const childRef = React.createRef();
class DocumentPages extends Component {
  static contextType = ConsentDataContext;
  constructor(props) {
    super(props);
    this.state = {
      currentOrdinal: 0,
      animateVisible: true,
      chapterTitle: "Documents",
      subjectPageEntryTime: "",
      validateFormFields: null,
      nextButtonEnabled: false,
      localNextStatus: false,
      scrollRef: null,
      loading: false,
      leastOrdinal: null,
      highestOrdinal: null,
      openComments: false,
    };
  }

  componentDidMount() {
    const { chapter, initializeDocuments, navigation } = this.props;
    this._unsubscribe = navigation.addListener("focus", () => {
      if (chapter.pages.length > 0) {
        const leastOrdinal = 0;
        // chapter.pages[0].ordinal;
        this.GotoInitialPage(leastOrdinal);
        const length = chapter.pages.length;
        const highestOrdinal = chapter.pages.length - 1;
        this.setState((prevState) => ({
          ...prevState,
          leastOrdinal,
          highestOrdinal,
          currentOrdinal: leastOrdinal,
        }));
      }
    });
    const documentIds = _.map(chapter.pages, (doc) => doc.id);
    initializeDocuments(documentIds);
  }

  componentWillUnmount() {
    this._unsubscribe();
  }

  GotoInitialPage = (ordinal) => {
    this.setState({ currentOrdinal: ordinal }, () => this.callMount());
  };

  setSubjectPageEntryTime = () => {
    this.setState({
      subjectPageEntryTime: moment().format(),
    });
  };

  getNextOrdinal = (currentOrdinal) => {
    const { chapter } = this.props;
    const { highestOrdinal } = this.state;
    if (chapter.pages.length > 0 && currentOrdinal <= highestOrdinal) {
      currentOrdinal = currentOrdinal + 1;
      const page = chapter.pages[currentOrdinal];
      // _.filter(chapter.pages, p => _.isEqual(p.ordinal, currentOrdinal));
      if (page) {
        return currentOrdinal;
      }
      return this.getNextOrdinal(currentOrdinal);
    }
    return currentOrdinal;
  };

  getPrevOrdinal = (currentOrdinal) => {
    const { chapter } = this.props;
    const { leastOrdinal } = this.state;
    if (chapter.pages.length > 0 && currentOrdinal >= leastOrdinal) {
      currentOrdinal = currentOrdinal - 1;
      const page = chapter.pages[currentOrdinal];
      // _.filter(chapter.pages, p => _.isEqual(p.ordinal, currentOrdinal));
      if (page) {
        return currentOrdinal;
      }
      return this.getNextOrdinal(currentOrdinal);
    }
    return leastOrdinal;
  };

  incrementCurrentOrdinal = () => {
    const {
      chapter,
      navigation,
      isRegistered,
      isKnowledgeReviewDone,
      signatureType,
      storeVisitedChapters,
      screenProps: { t },
      visitedChapters,
      language,
      selectedSubject,
      krDoneBy,
    } = this.props;
    const { currentOrdinal } = this.state;
    const currChapterOrdinal = navigation.getParam("ordinal");
    this.setState({ animateVisible: false });
    if (chapter.pages.length > 0) {
      const loCurrentOrdinal = this.getNextOrdinal(currentOrdinal);
      setTimeout(() => {
        this.setState(
          {
            subjectPageEntryTime: moment().format(),
            currentOrdinal: loCurrentOrdinal,
            animateVisible: true,
          },
          () => this.setExitTimeAndStoreNavTour()
        );
      }, 150);
    } else {
      onSelectingNextChapter(
        this.context.languages,
        language,
        currChapterOrdinal,
        navigation,
        isRegistered,
        signatureType,
        isKnowledgeReviewDone,
        storeVisitedChapters,
        visitedChapters,
        selectedSubject.subjectConsent.isKrDone,
        krDoneBy
      );
    }
  };
  decrementCurrentOrdinal = () => {
    const { currentOrdinal } = this.state;
    this.setState({ animateVisible: false });
    const loCurrentOrdinal = this.getPrevOrdinal(currentOrdinal);
    setTimeout(() => {
      this.setState(
        {
          subjectPageEntryTime: moment().format(),
          currentOrdinal: loCurrentOrdinal,
          animateVisible: true,
        },
        () => this.setExitTimeAndStoreNavTour()
      );
    }, 150);
  };

  setExitTimeAndStoreNavTour = () => {
    const { updateSubjectNavTour, chapter } = this.props;
    updateSubjectNavTour();
    if (chapter.pages.length > 0) {
      this.insertSubNavTour();
    }
  };

  callMount = () => {
    const { updateSubjectNavTour } = this.props;

    updateSubjectNavTour();

    this.insertSubNavTour();
  };

  insertSubNavTour = () => {
    const { chapter, storeSubjectNavTour } = this.props;
    const currentPage = this.getCurrentDoc();
    const obj = buildSubjectNavTour(
      moment().format(),
      null,
      chapter.id,
      currentPage.id,
      0
    );
    storeSubjectNavTour(obj);
  };

  getButtonNameBasedOnCurrentOrdinal = () => {
    const {
      chapter: { pages },
      screenProps: { t },
    } = this.props;
    const { currentOrdinal, highestOrdinal } = this.state;
    if (highestOrdinal === currentOrdinal) {
      return t("NextChapter");
    } else return t("Next");
  };
  getPreviousButtonNameBasedOnCurrentOrdinal = () => {
    const {
      screenProps: { t },
    } = this.props;
    const { currentOrdinal, leastOrdinal } = this.state;
    if (currentOrdinal === leastOrdinal) {
      return t("PreviousChapter");
    }
    return t("Previous");
  };

  hasPreviousPage = () => {
    const { currentOrdinal, leastOrdinal } = this.state;
    return currentOrdinal > leastOrdinal;
  };

  hasPreviousChapter = () => {
    const { navigation } = this.props;
    const currChapterOrdinal = navigation.getParam("ordinal");
    const {
      screenProps: { locale },
    } = this.props;
    return prevButtonVisible(
      this.context.languages,
      locale,
      currChapterOrdinal
    );
  };

  getCurrentDoc = () => {
    const { chapter } = this.props;
    const { currentOrdinal } = this.state;
    const currentDoc = chapter.pages[currentOrdinal];
    // .filter((doc) => doc.ordinal === currentOrdinal)[0];
    return currentDoc;
  };

  onNextChapter = (
    language,
    currChapterOrdinal,
    navigation,
    isRegistered,
    signatureType,
    isKnowledgeReviewDone,
    storeVisitedChapters,
    visitedChapters
  ) => {
    const {
      updateSubjectNavTour,
      selectedSubject,
      updateDocsReview,
      krDoneBy,
    } = this.props;
    updateSubjectNavTour();
    if (isRegistered) {
      this.saveDocumentsReviewed(
        language,
        currChapterOrdinal,
        navigation,
        isRegistered,
        signatureType,
        isKnowledgeReviewDone,
        storeVisitedChapters,
        visitedChapters
      );
    } else {
      // to maintain temporary local status for docs review
      updateDocsReview();
      onSelectingNextChapter(
        this.context.languages,
        language,
        currChapterOrdinal,
        navigation,
        isRegistered,
        signatureType,
        isKnowledgeReviewDone,
        storeVisitedChapters,
        visitedChapters,
        selectedSubject.subjectConsent.isKrDone,
        krDoneBy
      );
    }
  };

  saveDocumentsReviewed = async (
    language,
    currChapterOrdinal,
    navigation,
    isRegistered,
    signatureType,
    isKnowledgeReviewDone,
    storeVisitedChapters,
    visitedChapters
  ) => {
    const {
      subjectData,
      subjectNavTours,
      formDataList,
      updateDocsReview,
      selectedSubject,
      screenProps: { t },
      krDoneBy,
      clearSubjectNavTour,
    } = this.props;
    const fdKeys = Object.keys(formDataList);
    let formFields = [];
    _.forEach(fdKeys, (fdKey) => {
      formFields = [...formFields, ...formDataList[fdKey]];
    });
    this.setState({ loading: true });
    try {
      const subject = {
        id: subjectData.id,
        formData: formFields,
        subjectConsent: {
          id: subjectData.subjectConsentId,
          isDocReviewed: true,
          navTours: subjectNavTours,
          subject: {
            id: subjectData.id,
          },
        },
      };
      const res = await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subjectData.id}/subjectConsents/${subjectData.subjectConsentId}`
        , subject);
      clearSubjectNavTour();
      this.setState({ loading: false });
      // to maintain temporary local status for docs review
      updateDocsReview();
      onSelectingNextChapter(
        this.context.languages,
        language,
        currChapterOrdinal,
        navigation,
        isRegistered,
        signatureType,
        isKnowledgeReviewDone,
        storeVisitedChapters,
        visitedChapters,
        selectedSubject.subjectConsent.isKrDone,
        krDoneBy
      );
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
      if (error.response) {
        showToast(t("StopPartFailed"), "danger", 2000);
      } else {
        showToast(t("NetworkErr"), "danger", 2000);
      }
    }
  };

  formatDate = (actualDate) => {
    if (actualDate) {
      var timeZone = moment.tz.guess();
      return moment
        .tz(actualDate, timeZone)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    }
    return null;
  };

  setChapterPageValidation = (validateFormFields) => {
    this.setState({
      validateFormFields,
    });
  };

  checkIfFormFieldsFilled = () => {
    const { validateFormFields } = this.state;
    if (validateFormFields === null) {
      return true;
    } else {
      const validation = validateFormFields();
      return validation;
    }
  };

  enableNextButton = () => {
    const { updateVisitedDocumentStatus } = this.props;
    this.setState({
      nextButtonEnabled: true,
    });
    const currentDoc = this.getCurrentDoc();
    if (currentDoc) {
      updateVisitedDocumentStatus(currentDoc.id);
    }
  };

  enableComponentNextButton = (value) => {
    this.setState({
      localNextStatus: value,
    });
    if (value) {
      this.enableNextButton();
    }
  };

  disableNextButton = () => {
    this.setState({
      nextButtonEnabled: false,
    });
  };

  isNextButtonEnabled = () => {
    const { localNextStatus } = this.state;
    const { documentChapter, selectedSubject } = this.props;
    const currentDoc = this.getCurrentDoc();
    if (
      documentChapter[currentDoc?.id] ||
      (selectedSubject.registered &&
        selectedSubject.subjectConsent.isDocReviewed) ||
      localNextStatus
    ) {
      return true;
    } else if (currentDoc == undefined) {
      return true;
    } else {
      return false;
    }
  };

  setScrollRefToDocs = (ref) => {
    const { scrollRef } = this.state;
    if (scrollRef == null) {
      this.setState({
        scrollRef: ref,
      });
    }
  };

  skipSelectedDocument = async () => {
    const {
      skipDocument,
      screenProps: { t },
      selectedSubject,
      subjectData,
      navigation,
      isRegistered,
      isKnowledgeReviewDone,
      signatureType,
      storeVisitedChapters,
      clearDocSignPosition,
    } = this.props;
    const { currentOrdinal, highestOrdinal } = this.state;
    const currentDoc = this.getCurrentDoc();
    try {
      const res = await api.put(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${selectedSubject?.id}/subjectConsents/${selectedSubject.subjectConsent.id}}/documents/${currentDoc.id}?skipDocument=true`);
      await skipDocument(res.data.id);
      this.setState((prevState) => ({
        ...prevState,
        highestOrdinal: prevState.highestOrdinal - 1,
      }));
      if (currentOrdinal > highestOrdinal - 1) {
        const currChapterOrdinal = navigation.getParam("ordinal");
        this.onNextChapter(
          subjectData.language,
          currChapterOrdinal,
          navigation,
          isRegistered,
          signatureType,
          isKnowledgeReviewDone,
          storeVisitedChapters,
          subjectData.visitedChapters
        );
      }
      clearDocSignPosition();
    } catch (error) {
      console.log(error);
      showToast(t("SkipFail"), "danger", 3000);
    }
  };

  onCommentClick = () => {
    if (this.state.openComments && childRef.current) {
      childRef.current.onAdd();
    }
    this.setState((prevState) => ({
      ...prevState,
      openComments: true,
    }));
  };

  onCommentCancelClick = () => {
    this.setState((prevState) => ({
      ...prevState,
      openComments: false,
    }));
  };

  render() {
    const {
      currentOrdinal,
      animateVisible,
      localNextStatus,
      scrollRef,
      loading,
      openComments,
    } = this.state;
    const {
      chapter,
      navigation,
      isRegistered,
      isKnowledgeReviewDone,
      subjectData,
      signatureType,
      onNextChapter,
      onPreviousChapter,
      menuVisible,
      storeVisitedChapters,
      isAgreedForChapters,
      handleModalClose,
      screenProps: { t },
      canSkipTheDocument,
      visitedChapters,
      language,
      currentUserType,
    } = this.props;
    const doc = this.getCurrentDoc();
    const currentDoc = _.isEmpty(doc) ? {} : doc;
    const { chapterTitle } = chapter;
    const nextButtonEnabled = this.isNextButtonEnabled();
    const currChapterOrdinal = navigation.getParam("ordinal");
    if (chapter.pages.length > 0) {
      return (
        <Container style={{ flex: 1 }}>
          {/* <NavigationEvents
                    onWillFocus={() => {
                        this.GotoInitialPage();
                    }}
                /> */}
          <MyLoader active={loading} />
          <ChapterTitle
            chapterType="ICF_DOCUMENTS"
            chapterTitle={chapterTitle}
            onCommentClick={this.onCommentClick}
          />
          <View
            style={{
              justifyContent: "center",
              flexDirection: "row",
              zIndex: 2,
            }}
          >
            <Text
              style={{
                marginVertical: 10,
                padding: 8,
                backgroundColor: "#fff3e0",
                borderBottomRightRadius: 5,
                borderTopRightRadius: 5,
                borderWidth: 1,
                borderColor: "#ffe0b2",
                color: "#fb8c00",
                fontSize: fontType("subFontSize"),
                justifyContent: "space-between",
              }}
            >
              <MaterialCommunityIcons
                name="information"
                size={fontType("subFontSize")}
                color="#fb8c00"
              />
              <Text style={{ fontFamily: "Inter" }}> {t("PleaseScroll")}</Text>
            </Text>
          </View>
          <View
            style={{ alignSelf: "center", justifyContent: "center", zIndex: 2 }}
          >
            {currentDoc.pageTitle ? (
              <Text style={chapterButtonStyles.documentTitle}>
                {currentDoc.pageTitle}
              </Text>
            ) : (
              <Text></Text>
            )}
          </View>
          {/* <Content ref={c => (this.component = c)} style={{ padding: 10, margin: 5 }}>
                    <View >
                        <ChapterPage 
                        animateVisible={animateVisible}
                        currentOrdinal={currentOrdinal}
                        currentDoc={currentDoc}
                        handleModalClose={handleModalClose}
                        setChapterPageValidation={this.setChapterPageValidation} />
                    </View>
                </Content> */}
          {/* <Content style={{flex: 1}} ref={c => (this.component = c)} style={{flex: 1, paddingRight: 10, paddingTop: 10 }}> */}
          <View style={{ flex: 1 }}>
            <ChapterPage
              animateVisible={animateVisible}
              currentOrdinal={currentOrdinal}
              currentDoc={currentDoc}
              handleModalClose={handleModalClose}
              setChapterPageValidation={this.setChapterPageValidation}
              t={t}
              enableNextButton={this.enableNextButton}
              disableNextButton={this.disableNextButton}
              enableComponentNextButton={this.enableComponentNextButton}
              setScrollRefToDocs={this.setScrollRefToDocs}
              scrollRef={scrollRef}
            />
          </View>
          {/* </Content> */}
          <View>
            <BottomSheet
              open={openComments}
              onClose={() =>
                this.setState((prevState) => ({
                  ...prevState,
                  openComments: false,
                }))
              }
            >
              <CommentsPage
                ref={childRef}
                addNewComment={true}
                onCommentCancelClick={this.onCommentCancelClick}
                chapterType={ChapterType.ICF_DOCUMENTS.key}
              />
            </BottomSheet>
          </View>
          {/* Next/Submit Button */}
          <View
            style={[
              { flexDirection: "column", backgroundColor: "rgb(145, 85, 253)" },
            ]}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {currentDoc.irbApprovedOn ? (
                <Text style={{ color: "white" }}>
                  {t("IRBApprovedDate")}: {currentDoc.irbApprovedOn}
                </Text>
              ) : (
                <Text />
              )}
              {currentDoc.documentVersion ? (
                <Text style={{ color: "white" }}>
                  {" "}
                  {t("Version")}: {currentDoc.documentVersion}{" "}
                </Text>
              ) : (
                <Text />
              )}
            </View>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View>
                {canSkipTheDocument(currentUserType, currentDoc.id) &&
                  currentDoc.isOptionalDocument && (
                    <TouchableOpacity
                      transparent
                      iconLeft
                      onPress={() => {
                        this.skipSelectedDocument();
                      }}
                      style={chapterButtonStyles.button}
                    >
                      <Text style={chapterButtonStyles.buttonTxt}>
                        {t("Skip")}
                      </Text>
                    </TouchableOpacity>
                  )}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {(this.hasPreviousChapter() || this.hasPreviousPage()) && (
                  <TouchableOpacity
                    transparent
                    iconLeft
                    onPress={() => {
                      scrollRef._root.scrollToPosition(0, 0, false);
                      this.getPreviousButtonNameBasedOnCurrentOrdinal() ===
                      t("Previous")
                        ? this.decrementCurrentOrdinal()
                        : onSelectingPrevChapter(
                            this.context.languages,
                            subjectData.language,
                            currChapterOrdinal,
                            navigation
                          );
                    }}
                    style={chapterButtonStyles.button}
                  >
                    <Text style={chapterButtonStyles.buttonTxt}>
                      {this.getPreviousButtonNameBasedOnCurrentOrdinal()}
                    </Text>
                  </TouchableOpacity>
                )}
                <TouchableOpacity
                  disabled={!nextButtonEnabled}
                  transparent
                  onPress={() => {
                    this.enableNextButton();
                    if (this.checkIfFormFieldsFilled()) {
                      scrollRef._root.scrollToPosition(0, 0, false);
                      setTimeout(() => {
                        this.getButtonNameBasedOnCurrentOrdinal() === t("Next")
                          ? this.incrementCurrentOrdinal()
                          : this.onNextChapter(
                              subjectData.language,
                              currChapterOrdinal,
                              navigation,
                              isRegistered,
                              signatureType,
                              isKnowledgeReviewDone,
                              storeVisitedChapters,
                              subjectData.visitedChapters
                            );
                      }, 0);
                      //    this.getButtonNameBasedOnCurrentOrdinal() === t('Next') ? this.incrementCurrentOrdinal() : this.onNextChapter(subjectData.language, currChapterOrdinal, navigation, isRegistered,signatureType,isKnowledgeReviewDone,storeVisitedChapters, subjectData.visitedChapters);
                    }
                  }}
                  style={
                    nextButtonEnabled
                      ? chapterButtonStyles.button
                      : chapterButtonStyles.buttonNextDisabled
                  }
                >
                  <Text
                    style={
                      nextButtonEnabled
                        ? [chapterButtonStyles.buttonTxt]
                        : chapterButtonStyles.nextTxtDisabled
                    }
                  >
                    {this.getButtonNameBasedOnCurrentOrdinal()}
                  </Text>
                  {/* <Icon name='arrow-forward' style={{color: Colors.textColor,marginLeft:-wp('1%'),marginRight:wp('2%') ,fontSize: wp('3.5%') }} /> */}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Container>
      );
    } else {
      return (
        <Container>
          <ChapterTitle
            chapterType="ICF_DOCUMENTS"
            chapterTitle={chapterTitle}
          />
          <NoDataPage message={t("NoData")} />
          <Footer style={chapterButtonStyles.footer}>
            {(this.hasPreviousChapter() || this.hasPreviousPage()) && (
              <TouchableOpacity
                transparent
                iconLeft
                onPress={() => {
                  this.getPreviousButtonNameBasedOnCurrentOrdinal() ===
                  t("Previous")
                    ? this.decrementCurrentOrdinal()
                    : onSelectingPrevChapter(
                        this.context.languages,
                        subjectData.language,
                        currChapterOrdinal,
                        navigation
                      );
                }}
                style={chapterButtonStyles.button}
              >
                <Text style={chapterButtonStyles.buttonTxt}>
                  {this.getPreviousButtonNameBasedOnCurrentOrdinal()}
                </Text>
              </TouchableOpacity>
            )}
            <TouchableOpacity
              disabled={!nextButtonEnabled}
              transparent
              onPress={() => {
                this.getButtonNameBasedOnCurrentOrdinal() === t("Next")
                  ? this.incrementCurrentOrdinal()
                  : this.onNextChapter(
                      subjectData.language,
                      currChapterOrdinal,
                      navigation,
                      isRegistered,
                      signatureType,
                      isKnowledgeReviewDone,
                      storeVisitedChapters,
                      subjectData.visitedChapters
                    );
              }}
              style={chapterButtonStyles.button}
            >
              <Text
                style={
                  nextButtonEnabled
                    ? [chapterButtonStyles.buttonTxt]
                    : chapterButtonStyles.nextTxtDisabled
                }
              >
                {t("NextChapter")}
              </Text>
            </TouchableOpacity>
          </Footer>
        </Container>
      );
    }
  }
}
export default DocumentPages;
