import React, { useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Text,
  Platform,
} from "react-native";
import _ from "lodash";
import { DocumentRightIcons } from "../common/constants";
import Hoverable from "../dashboard/Hoverable";
import ToolTip from "../dashboard/Tooltip";
import {
  MaterialIcons,
  MaterialCommunityIcons,
  AntDesign,
  Feather,
} from "@expo/vector-icons";
import { Colors } from "../../constants/ui/colorScheme";
import { fontType } from "../../styles/font";
import { Icon } from "native-base";
const Action = (props) => {
  const {
    type,
    index,
    onClick,
    setLayoutData,
    onHoverIn,
    onHoverOut,
    isSignatureCompleted,
    icon,
  } = props;

  const renderPrintIcon = (index) => {
    return (
      <TouchableOpacity
        onPress={() => {
          onClick();
        }}
        onLayout={setLayoutData}
      >
        <Hoverable
          onHoverIn={() => {
            onHoverIn(index, "print");
          }}
          onHoverOut={() => onHoverOut()}
        >
          <AntDesign
            name="printer"
            color={Colors.radioGroup}
            size={fontType("StatusIcon")}
          />
        </Hoverable>
      </TouchableOpacity>
    );
  };

  const renderVerifyIcon = (isReviewed, index) => {
    return (
      <View
        style={[
          styles.printReviewIconContainerStyle,
          {
            backgroundColor: isReviewed ? Colors.buttonwithbg : "grey",
            borderColor: isReviewed ? Colors.buttonwithbg : "grey",
            marginLeft: 5,
          },
        ]}
      >
        <TouchableOpacity onPress={() => onClick()} onLayout={setLayoutData}>
          {isReviewed ? (
            <MaterialIcons name="check" color={Colors.background} size={14} />
          ) : (
            <Hoverable
              onHoverIn={() => {
                onHoverIn(index, "verify");
              }}
              onHoverOut={() => onHoverOut()}
            >
              <MaterialIcons name="check" color={Colors.background} size={14} />
            </Hoverable>
          )}
        </TouchableOpacity>
      </View>
    );
  };

  const ifSubjectISConsented = () => {
    return (
      <Text style={styles.legendText}>
        {" "}
        <TouchableOpacity onPress={() => onClick()}>
          <AntDesign
            name="printer"
            color={Colors.radioGroup}
            size={fontType("StatusIcon")}
          />
        </TouchableOpacity>
        {"  "}
        <AntDesign
          name="checkcircle"
          size={fontType("ChapterIcon")}
          color={Colors.checkCircle}
        />
      </Text>
    );
  };

  switch (type) {
    case DocumentRightIcons.ARE_SIGNATURES_DONE:
      if (icon.name == "arrow-forward") {
        return (
          <Icon
            name="arrow-forward"
            style={{
              color: Colors.buttonwithbg,
              fontSize: fontType("ChapterIcon"),
              marginLeft: 5,
            }}
          />
        );
      } else {
        return (
          <AntDesign
            name={icon.name}
            size={fontType("ChapterIcon")}
            color={Colors.checkCircle}
            style={{ marginLeft: 5 }}
          />
        );
      }
    case DocumentRightIcons.CONSENTED_OR_DISCONTINUED:
      return ifSubjectISConsented();
    case DocumentRightIcons.PRINT_AND_SIGN:
      return renderPrintIcon(index);
    case DocumentRightIcons.VERIFY_PRINT_AND_SIGN:
      return renderVerifyIcon(isSignatureCompleted, index);
    default:
      return <></>;
  }
};

const DocumentRightChildren = (props) => {
  const { actionList, index, isSignatureCompleted } = props;
  const [toolTip, setToolTip] = useState({
    showToolTip: false,
    layout: {},
    index: 1,
    type: "print",
    noOfElements: !_.isEmpty(actionList) ? actionList.length : 1,
  });

  const handleTooltip = (index, type) => {
    const loToolTip = {
      ...toolTip,
      showToolTip: true,
      index: (index + 1) * 10,
      type: type,
    };
    setToolTip(loToolTip);
  };

  const setLayoutData = (e) => {
    const loToolTip = { ...toolTip, layout: e?.nativeEvent?.layout };
    setToolTip(loToolTip);
  };

  const hoverOut = () => {
    setTimeout(() => {
      setToolTip({ ...toolTip, showToolTip: false });
    }, 500);
  };

  return (
    <>
      {_.map(actionList, (action) => {
        if (action.type !== "Signatures") {
          if (Platform.OS === "web") {
            return (
              <>
                <ToolTip
                  tip={
                    action.type !== "PrintNSign"
                      ? "Verify Print And Sign"
                      : "Print And Sign"
                  }
                >
                  <Action
                    type={action.type}
                    onClick={action.onClick}
                    onHoverIn={handleTooltip}
                    onHoverOut={() => hoverOut()}
                    setLayoutData={setLayoutData}
                    index={index}
                    isSignatureCompleted={isSignatureCompleted}
                    icon={action.icon}
                  />
                  <Text>{"  "}</Text>
                </ToolTip>
              </>
            );
          } else {
            return (
              <>
                <Action
                  type={action.type}
                  onClick={action.onClick}
                  onHoverIn={handleTooltip}
                  onHoverOut={() => hoverOut()}
                  setLayoutData={setLayoutData}
                  index={index}
                  isSignatureCompleted={isSignatureCompleted}
                  icon={action.icon}
                />
                <Text>{"  "}</Text>
              </>
            );
          }
        }
        return (
          <Action
            type={action.type}
            onClick={action.onClick}
            onHoverIn={handleTooltip}
            onHoverOut={() => hoverOut()}
            setLayoutData={setLayoutData}
            index={index}
            isSignatureCompleted={isSignatureCompleted}
            icon={action.icon}
          />
        );
      })}
    </>
  );
};

export default DocumentRightChildren;

var styles = StyleSheet.create({
  buttonSbmt: {
    backgroundColor: Colors.button,
  },
  buttonTxt: {
    color: Colors.textColorwithbg,
    textAlign: "center",
    fontWeight: "200",
    fontSize: fontType("ButtonTxt"),
    fontFamily: "Inter_Bold",
  },
  Footer: {
    backgroundColor: Colors.footer,
    alignItems: "center",
    margin: 10,
  },
  documentTxt: {
    fontSize: fontType("BasicFont"),
    color: Colors.text,
    fontFamily: "Inter",
  },
  title: {
    padding: 10,
    backgroundColor: Colors.background,
  },
  legendText: {
    fontSize: fontType("subFont"),
    fontFamily: "Inter",
    paddingLeft: 15,
    color: Colors.reglabels,
  },
  printReviewIconContainerStyle: {
    display: "flex",
    height: 20,
    width: 15,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: Colors.buttonwithbg,
  },
});
