
import React, { Component } from 'react';
import { View, Text, Linking, } from 'react-native';
import { Container, ListItem ,List} from 'native-base';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../constants/ui/colorScheme';
import DialogMenus from '../dialogMenus/navigationMenu';
import { getLanguageData   } from '../../utils/consentData';
import NavigationMenu from '../dialogMenus/navigationMenu';
import {fontType} from '../../styles/font'
import { TouchableOpacity } from 'react-native-gesture-handler';
import {constStyles} from '../../styles/constStyles';
import api from '../../utils/api';
import Constants from '../../utils/constants';
import { CommonActions } from '@react-navigation/native';
export default class NavigateToAdmin extends Component {

    constructor(props) {
        super(props);
    }

     submitSubjectData = async ()  => {
        const {subjectData, navigation, handleHeaderMenuDialoge, clearSubjectNavTour, isRemoteConsent, otp, onStopConsentClick } = this.props;
        const { routeName } = navigation.state;
        if(isRemoteConsent) {
            handleHeaderMenuDialoge();
              navigation.dispatch(
                CommonActions.reset({
                    index: 0,
                    routes: [{ name: 'SubjectAuthStack' , params: { otp: otp, }},],
                })
            )
        }else if(routeName !== 'CompletedScreen'){
            clearSubjectNavTour();
            onStopConsentClick();
        } else {         
            handleHeaderMenuDialoge();
            clearSubjectNavTour();
            // navigation.original.reset({
            //     index: 0,
            //     routes: [
            //       {
            //         name: 'AdminAuthStack',
            //       },
            //     ],
            //   })
            const loginRequestId =  window.sessionStorage.getItem('loginRequestId');
            delete api.defaults.headers.common.Authorization;
            setTimeout(() => {
            window.location.assign(`${Constants.ADMIN_PORTAL_BASE_URL}/saml/logout/${loginRequestId}?redirectUrl=${Constants.ADMIN_PORTAL_BASE_URL}/api/webapp/logout`);
            },100);
        }
        
        // navigation.navigate('AdminAuthStack', { screen: 'LoginPage' });
        // try {
        // await api.post('consent/subject/submit', subjectData )
        // }
        // catch(error) {
        //     console.log(error);
        //     Toast.show('Failed to save.', {
        //         // duration: 5000,
        //         position:200,
        //         visible: true,
        //         backgroundColor:'#e84338',
        //         delay: 0
        //     })
        // }
    }

    render() {
        const { navigation,language,handleHeaderMenuDialoge,signatureType,isRegistered,navigationMenus,subjectData, languages, isSubSignsDone, isRemoteConsent, currentSubDocPosition, isDocSignsCompleted,screenProps } = this.props;
        const chapters = getLanguageData(languages, language);
        const { routeName } = navigation.state;
        return (
            <View >
                <View style={{paddingTop:10}}>
                    
                    <View>
                        <List>
                            <NavigationMenu isDocSignsCompleted={isDocSignsCompleted} currentSubDocPosition={currentSubDocPosition} navigationMenus={navigationMenus} isRegistered={isRegistered} signatureType={signatureType} chapters={chapters} navigation={navigation} handleHeaderMenuDialoge={handleHeaderMenuDialoge} noOfchapters={7} visitedChapters={subjectData.visitedChapters} />
                        </List>
                        <TouchableOpacity 
                            style={[constStyles.buttonStyle,{backgroundColor: Colors.errorButton}]}
                            onPress={() => this.submitSubjectData()}
                        >
                            <Text 
                                style = {[constStyles.buttonTxt, {paddingHorizontal:50}]}
                            >
                            {isRemoteConsent ? screenProps.t('Logout') : (routeName !== 'CompletedScreen' && routeName !== 'CancelFlow' ) ? 'Stop Consent' : 'EXIT'}
                        </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        );
    }
}
