import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from "react-native";
import { Container } from "native-base";
import { Colors } from "../../constants/ui/colorScheme";
import { fontType } from "../../styles/font";
import { constStyles } from "../../styles/constStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setSelectedLanguage } from "../../actions/language";
import { setCurrentUserType } from "../../actions/consentFlow";
import { ConsentDataContext } from "../../providers/ConsentDataProvider";
import _ from "lodash";
import { filterAndSetUnsignedDocuments } from "../../actions/subjectConsentDocument";
import { clearClientUserDetails } from "../../actions/clientUser";
import { generateToken } from "../../actions/subject";
import PropTypes from "prop-types";
import showToast from '../../utils/toast'

class HandoverTabTosub extends Component {
  static contextType = ConsentDataContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const {userType, navigation} = this.props
    if(userType !== 3){
      navigation.navigate("Admin", {
        screen: "UnauthorizedScreen", 
      })
    }
  }
  onContinue = async() => {
    const {
      generateToken,
      navigation,
      selectLanguage,
      selectedSubject,
      clearClientUserDetails,
      screenProps: { setLocale,t },
      setCurrentUserType: loSetCurrentUserType,
      signatureTypes,
      documents,
      filterAndSetUnsignedDocuments,
    } = this.props;
    try{
      await generateToken(selectedSubject, signatureTypes[0]);
      clearClientUserDetails();
      setLocale(selectedSubject.locale);
      const language = {
        languageId: selectedSubject.languageId,
        language: selectedSubject.locale,
      };
      selectLanguage(language);
      loSetCurrentUserType(signatureTypes[0]);
      filterAndSetUnsignedDocuments(documents, selectedSubject.larAsWitness);
      if (!_.isEmpty(signatureTypes) && signatureTypes[0] == 4) {
        navigation.navigate("Consent", {
          screen: "SubDocsPage", 
        })
      } else {
        navigation.navigate("Consent", {
          screen: "ConsentOverviewScreen", 
        })
    }

    }catch(error){
      console.log(error);
      showToast(t('ProccedFail'), 'danger', 3000);
    }
  };

  getNameOfTheSignatureType = () => {
    const { signatureTypes } = this.props;
    const type = signatureTypes[0];
    switch (type) {
      case 1:
        return "subject";
      case 2:
        return "LAR";
      case 4:
        return "witness";
      default:
        break;
    }
  };
  render() {   
      return (
        <Container>
          <View
            style={{
            flexDirection: "column",
            justifyContent: "center",
            padding: wp("2.3%"),
            marginTop: Platform.OS == "web" ? 200 : 350,
            }}
          >
            <Text style={styles.content}>
              {`Please give the device to the ${this.getNameOfTheSignatureType()} to continue with the informed consent process.`}
            </Text>
            <TouchableOpacity
              style={constStyles.buttonStyle}
              onPress={() => this.onContinue()}
            >
              <Text style={constStyles.buttonTxt}>CONTINUE</Text>
            </TouchableOpacity>
          </View>
        </Container>
      );
 }
    
}

var styles = StyleSheet.create({
  content: {
    color: Colors.text,
    fontSize: fontType("Heading1"),
    padding: 10,
    marginTop: 10,
    textAlign: "center",
    fontFamily: "Inter",
  },
});

HandoverTabTosub.defaultProps = {
  selectedSubject: {},
  documents: [],
  signatureTypes: [],
  navigation: { getParam: () => {} },
  screenProps: { setLocale: () => {} },
  selectLanguage: () => {},
  setCurrentUserType: () => {},
  filterAndSetUnsignedDocuments: () => {},
  clearClientUserDetails: () => {},
  generateToken: () => {},
  userType: 3,
};

HandoverTabTosub.propTypes = {
  selectedSubject: PropTypes.object,
  documents: PropTypes.array,
  signatureTypes: PropTypes.array,
  navigation: PropTypes.object,
  screenProps: PropTypes.object,
  selectLanguage: PropTypes.func,
  setCurrentUserType: PropTypes.func,
  filterAndSetUnsignedDocuments: PropTypes.func,
  clearClientUserDetails: PropTypes.func,
  generateToken: PropTypes.func,
  userType: PropTypes.number
};

const mapStateToProps = (state) => ({
  selectedSubject: state.selectedSubject,
  documents: state.subjectConsentDocument.list,
  signatureTypes: state.consentFlow.signatureTypes,
  studySiteId: state.studySite.studySiteId,
  userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectLanguage: setSelectedLanguage,
      setCurrentUserType,
      filterAndSetUnsignedDocuments,
      clearClientUserDetails: () => dispatch(clearClientUserDetails()),
      generateToken,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HandoverTabTosub);
