import { STORE_REGISTRATION_DATA, STORE_SUBJECT_REGISTERED_DATA, SUBJECT_REGISTRATION_FAILED } from '../actions/subjectRegistrationForm';
import { STORE_QUIZ_ATTEMPT_DATA, } from '../actions/quizAttempts';
import { STORE_SIGNATURE_DATA, } from '../actions/subjectSignatureData';
import { LAR_FORM_DATA } from '../actions/larForm';
import { STORE_CONSENT_TOKEN_DATA } from '../actions/consentToken';
import { UPDATE_CONSENT_COMPLETION_STATUS, STORE_SUBJECT_STATUS, SET_SUBJECT_TO_EMPTY, STORE_ORDINAL_FOR_NAVIGATIONS, STORE_SIGNATURE_TYPE } from '../actions/subject';
import { SET_SELECTED_LANGUAGE } from '../actions/language';
import {
    CLEAR_SUBJECT_DATA, STORE_SELETED_SUBJECT_DETAILS_IN_SUBJECT, STORE_IS_AGREE_FOR_CHAPTERS, STORE_IS_SUBSIGNS_DONE,
    STORE_SUBJECT_NAVTOUR, UPDATE_SUBJECT_NAVTOUR, CLEAR_SUBJECT_NAVTOUR, CLEAR_SUBJECT_DOCUMENT_SIGNATURES
} from '../actions/subject';
import initialState from '../store/initialState';
import _ from 'lodash';
import moment from 'moment';
import { getOverallTimeSpentBySubject } from '../utils/subjectNavTourUtils'

export default (state = initialState.subject, action) => {
    switch (action.type) {
        case STORE_REGISTRATION_DATA:
            return {
                ...state,
                formData: action.subject.formFields,
                firstName: action.subject.subjectDetails.firstName,
                lastName: action.subject.subjectDetails.lastName,
                dob: action.subject.subjectDetails.dob,
                gender: action.subject.subjectDetails.gender,
                phoneNo: action.subject.subjectDetails.phoneNo,
                isRegistered: true,
            };
        case STORE_SUBJECT_REGISTERED_DATA:
            return {
                ...state,
                id: action.id,
                isRegistered: action.registered,
                subjectConsent: {
                    id: action.subjectConsentId, 
                },
                isRemoteConsent: action.isRemoteConsent
            };
        case STORE_SIGNATURE_DATA:
            let sdsList = [...state.subjectDocumentSignatures, action.subjectDocumentSignature];
            sdsList = _.uniqBy(sdsList, 'id');
            return {
                ...state,
                subjectDocumentSignatures: sdsList,
            }
        case LAR_FORM_DATA:
            return {
                ...state,
                larDetails: {
                    name: action.larDetails.larName,
                    larRelation: action.larDetails.larRelation,
                }
            };
        case STORE_QUIZ_ATTEMPT_DATA:
            return {
                ...state,
                quizAttempts: action.quizAttempts,
                isKnowledgeReviewDone: true,
            };

        case STORE_CONSENT_TOKEN_DATA:
            return {
                ...state,
                consentId: action.consentTokenId,
            };
        case UPDATE_CONSENT_COMPLETION_STATUS:
            return {
                ...state,
                consentCompleted: action.consentCompleted,
            };
        case SET_SELECTED_LANGUAGE:
            return {
                ...state,
                language: action.language,
                languageId: action.languageId,
            };
        case STORE_SUBJECT_STATUS:
            return {
                ...state,
                status: action.status,
            };
        case SET_SUBJECT_TO_EMPTY:
            return {
                ...state,
                ...action.subject,
            };
        case CLEAR_SUBJECT_DATA:
            return {
                // ...state,
                ...action.subject,
            };
        case STORE_SELETED_SUBJECT_DETAILS_IN_SUBJECT:
            return {
                ...state,
                ...action.subject,
            };
        case STORE_SIGNATURE_TYPE:
            return {
                ...state,
                signatureType: action.signatureType,
            };
        case STORE_ORDINAL_FOR_NAVIGATIONS:
            return {
                ...state,
                visitedChapters: action.ordinal,
            };
        case STORE_SUBJECT_NAVTOUR:
            return {
                ...state,
                navTour: state.navTour.concat(action.subjectNavTour)
            }
        case UPDATE_SUBJECT_NAVTOUR:
            let navTour = [...state.navTour];
            const arrLength = navTour.length;
            if (arrLength > 0) {
                let obj = _.last(state.navTour)
                if (obj.exitTime == null) {
                    const exitTime = moment().format()
                    const diffTime = getOverallTimeSpentBySubject(obj.entryTime, exitTime)
                    obj.totalTimeSpent = diffTime;
                    obj.exitTime = exitTime;
                    navTour[arrLength - 1] = obj;
                    return {
                        ...state,
                        navTour: navTour
                    }
                } else return state;
            } else return state;
        case CLEAR_SUBJECT_NAVTOUR:
            return {
                ...state,
                navTour: []
            }
        case STORE_IS_AGREE_FOR_CHAPTERS:
            return {
                ...state,
                isAgreedForChapters: {
                    ...state.isAgreedForChapters,
                    [action.isAgreedForChapters.key]: action.isAgreedForChapters.value
                }
            }
        case STORE_IS_SUBSIGNS_DONE:
            return {
                ...state,
                isSubSignsDone: true
            }
        case SUBJECT_REGISTRATION_FAILED:
            return {
                ...state,
                isRegistered: false,
            }
        case CLEAR_SUBJECT_DOCUMENT_SIGNATURES:
                return {
                    ...state,
                    subjectDocumentSignatures:[],
        };
        default:
            return state;
    }
};

