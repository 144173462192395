import React from "react";
import TextAvatar from "react-native-text-avatar";
import { TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { Colors } from "../constants/ui/colorScheme";
import PropTypes from 'prop-types'

function UserProfile(props) {
  const { userFullName, setNavigation, logoutMenuDialoge, navigation } = props;

  const getUserName = () => {
    const userNameArr = userFullName?.split(" ");
    if (userNameArr && userNameArr.length >= 2) {
      return userNameArr[0].charAt(0) + "" + userNameArr[1].charAt(0);
    }
    return "";
  };

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        marginRight: 5,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          setNavigation(navigation);
          logoutMenuDialoge();
        }}
        title="Info"
        color={Colors.tobg}
        style={{
          width: 40,
          height: 40,
        }}
      >
        <TextAvatar
          backgroundColor={"#3a35414d"}
          textColor={"white"}
          size={40}
          type={"circle"}
        >
          {getUserName()}
        </TextAvatar>
      </TouchableOpacity>
    </View>
  );
}
UserProfile.defaultProps = {
  userFullName: "",
  setNavigation: () => {},
  logoutMenuDialoge: () => {},
  navigation: {},
};

UserProfile.propTypes = {
  userFullName: PropTypes.string,
  setNavigation: PropTypes.func,
  logoutMenuDialoge: PropTypes.func,
  navigation: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userFullName: state.clientUser.selectedUser.fullName,
});

export default connect(mapStateToProps, null)(UserProfile);
