import _ from 'lodash';

export const disableTap = (subjectDocumentSignatures, documentSignatureId) => {
    const docsWithSign = subjectDocumentSignatures.filter(sds => sds.id === documentSignatureId);
    return !_.isEmpty(docsWithSign.signatureFilePath);
}

export const buildCounterDocumentSignatures = (documentSignatures) => {
    const indexOfLar = _.findIndex(documentSignatures, ['signatureType', 2]);
    const indexOfSubSig = _.findIndex(documentSignatures, ['signatureType', 1]);
    let dss = [...documentSignatures];
    if(indexOfLar !== -1 && indexOfSubSig !== -1) {
        const larSignatureFilePath = dss[indexOfLar].signatureFilePath;
        const subjSignatureFilePath = dss[indexOfSubSig].signatureFilePath;
        if(larSignatureFilePath !== null) {
            _.remove(dss, (ds) => {
                return ds.signatureType == 1;
              });
            return dss;
        } else if (subjSignatureFilePath !== null){
            _.remove(dss, (ds) => {
                return ds.signatureType == 2;
              });
            return dss;
        } else {
            _.remove(dss, (ds) => {
                if(ds.signatureType == 4 && ds.isDeleted == true) {
                    if(ds.isOptional == false) return true;
                } else if(ds.isDeleted == true) {
                    return true;
                }
              });
            return dss;  
        }
    } else {
       return documentSignatures;
    }
}

export const hasLarSigned = (documentSignatures) => {
    const indexOfLar = _.findIndex(documentSignatures, ['signatureType', 2]);
    if(indexOfLar !== -1) {
        const larSignatureFilePath = documentSignatures[indexOfLar].signatureFilePath;
        if(larSignatureFilePath !== null) {
            return true;
        } return false;
    } return false
}
