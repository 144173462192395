import React, { useEffect } from 'react';
import { View, StyleSheet, Text, Dimensions } from 'react-native';
import CommentBox from './commentBox';
import TryalIcon from '../common/icon';
import { IconType, width, ChapterType } from '../common/constants';
import { Colors } from '../../constants/ui/colorScheme';
import _ from 'lodash';
import CommentResponseBox from './commentResponseBox';
import CommentReviewActions from './commentReviewAction';
import { useDispatch , useSelector} from 'react-redux';
import { deleteComment, deleteResponse } from '../../actions/comments';
import { formatDateTime } from '../../utils/dateFormatUtils';


const SCREEN_WIDTH = width();
const CommentViewBoxStyles= StyleSheet.create({
    containerStyle:{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
        justifyContent: 'flex-start',
        width: SCREEN_WIDTH*0.8,
        marginLeft: SCREEN_WIDTH*0.01
    },
    headerStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // alignSelf: 'center',
        marginBottom: '5px',
        // width: SCREEN_WIDTH*0.8,
        // alignItems: 'center',
    },
    iconsStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    textInpuStyle: {
        borderColor: Colors.borderGreyColor,
        borderWidth: 1,
        width: SCREEN_WIDTH*0.6,
        minHeight: '100%',
        borderRadius: 5,
        justifyContent: 'center',
        alignSelf: 'center'
    },
    textStyle: {
        textAlign: SCREEN_WIDTH >= 800 ? 'center' :'flex-start',
        fontFamily: 'Inter',
        marginBottom: 20,
        fontSize: 14,
    }
});


const CommentViewBox = (props) => {
    const { commentDetails, onEdit, onCommentSave, onCommentCancel,
        isResponse=false, isSubjectView, onCommentDelete, updateResponse, chapterType } = props;

    const dispatch = useDispatch();
    const { isRemoteConsent } = useSelector(state => state.subject);
    const { selectedSubject } = useSelector(state => state);
    const { studySiteState } = useSelector(state => state.studySite);
    const { studySite } = useSelector(state => state);

    const onDelete = async() => {
        try {
            const res = isResponse ? dispatch(deleteResponse(selectedSubject?.id, commentDetails.id, commentDetails?.subjectComment?.id)): dispatch(deleteComment(selectedSubject?.id, commentDetails.id))
            onCommentDelete();
        } catch (error) {
            console.log(error);
        }
    }

    const getCommentBox = () => {
        if(!isSubjectView && !isResponse) {
            return (
                <CommentResponseBox 
                    commentDetails={commentDetails}
                    onCommentSave = {onCommentSave}
                    onCommentCancel = {onCommentCancel}
                    isSubjectView = {isSubjectView}
                    onEdit = {onEdit}
                    updateResponse = {updateResponse}
                />
            )
        }
        if(!_.isEmpty(commentDetails?.subjectCommentReplies)){
            return (
                <CommentResponseBox 
                    commentDetails={commentDetails}
                    onCommentSave = {onCommentSave}
                    onCommentCancel = {onCommentCancel}
                    isSubjectView = {isSubjectView}
                    onEdit = {onEdit}
                    updateResponse = {updateResponse}
                />
            )
        }
        return (
            <CommentBox 
            isEditing={commentDetails.edit} 
            commentDetails={commentDetails}
            onCommentSave = {onCommentSave}
            onCommentCancel = {onCommentCancel}
            isResponse = {isResponse}
            isSubjectView = {isSubjectView}
            onCommentDelete = {onCommentDelete}
            chapterType={chapterType? chapterType : null}
            />
        )
    }
    return (
        <View style = {[CommentViewBoxStyles.containerStyle, {width: isResponse ? SCREEN_WIDTH*0.7 : SCREEN_WIDTH*0.85, marginLeft: !isResponse ? SCREEN_WIDTH*0.01 : 0 }]}>
           <View style = {[CommentViewBoxStyles.headerStyle, {width: isResponse ? SCREEN_WIDTH*0.8 : SCREEN_WIDTH*0.85}]}>
                { !isResponse && 
                    <View style={{flexDirection: SCREEN_WIDTH >= 800 ?  'row' : 'none', justifyContent: 'flex-start'}}>
                        <Text style = {CommentViewBoxStyles.textStyle}>Comment: {formatDateTime(commentDetails?.createdTs)}  &nbsp;&nbsp;</Text>
                        <Text style = {CommentViewBoxStyles.textStyle}>Chapter: {commentDetails?.chapterType ? ChapterType[commentDetails?.chapterType].value: null}</Text>
                    </View>
                }
                { isResponse && <Text style = {CommentViewBoxStyles.textStyle}>Response: {formatDateTime(commentDetails?.createdTs)}</Text>}
                <View style = {CommentViewBoxStyles.iconsStyle}>
                    { !isResponse && commentDetails.id && !_.isEmpty(commentDetails?.subjectCommentReplies) && <CommentReviewActions isReviewed = {commentDetails?.resolved} isSubjectView={isSubjectView} commentDetails={commentDetails} onCommentSave={onCommentSave}/>}
                    {
                        (!(commentDetails.edit) && (!!commentDetails?.id && _.isEmpty(commentDetails?.subjectCommentReplies)) && !commentDetails?.resolved && ((isSubjectView && !isResponse) || (!isSubjectView && isResponse)) ) ? 
                        <React.Fragment>
                            <TryalIcon iconName='delete' iconType={IconType.MATERIAL} onClick={onDelete}/>
                            <TryalIcon iconName='edit' iconType={IconType.MATERIAL} onClick={onEdit}/>
                        </React.Fragment>
                        : <React.Fragment></React.Fragment>
                    }
                </View>
           </View>
           {
            getCommentBox()
           }
            
        </View>
    )
}

export default CommentViewBox;
