import React, { Component } from 'react';
import { View, Platform, StyleSheet, TouchableOpacity, ActivityIndicator, Text, FlatList } from 'react-native';
import {
   Container, Body, Right, List, ListItem, Content, Card, FooterTab, Footer, Button, Title, Icon, Left, Switch,
} from "native-base";
// import {styles} from '../consentSignature/styles/documentListStyles';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import _ from 'lodash';
import { MaterialIcons, MaterialCommunityIcons, AntDesign, Feather } from '@expo/vector-icons';
import moment from 'moment';
import ChapterTitle  from '../chapterTitle/ChapterTitle';
import { Colors } from '../../constants/ui/colorScheme';
import {formatDateTime} from '../../utils/dateFormatUtils'
import {ConsentDataContext} from '../../providers/ConsentDataProvider'
import { getConsentChapter } from '../../utils/consentData';
import {fontType} from '../../styles/font';
import MyLoader from '../../utils/webLoader';




export default class DocumentsList extends Component {
   static contextType = ConsentDataContext;
   constructor(props) {
      super(props);
      this.state = {
         chapterTitle:'Documents to Sign',
      }
   }

   componentDidMount = () => {
      this.submitConsentData();
   }

   submitConsentData = () => {
      const {submitConsentData, subject} = this.props;
      // if(subject.isSubSignsDone == false) {
      submitConsentData();
      // }
   }

   getSignaturesData = (sdsId) =>  {
        const {subject, screenProps: { t }, documentPages} = this.props;
        let sds = _.find(subject.subjectDocumentSignatures, {'id': sdsId} );        
        if(!_.isEmpty(sds)){
            let doc = _.find(documentPages, {'id': sds.documentPageId} )
            return (
                <View>
                    <Text style={styles.documentTxt}>{doc.pageTitle}</Text>
                    <View style={{margin:wp('1%'),}}>
                    <Text style={{fontSize:fontType('subFont'), color:Colors.signDetails, fontFamily:'Inter'}}>{t('SignedBy')}: {sds?.signedBy}</Text>
                    <Text style = {{ fontSize:fontType('subFont'),  color:Colors.signDetails, fontFamily:'Inter' }} >{t('SignedOn')}: { formatDateTime(sds?.signatureDate)}</Text>
                    </View>
                </View>
            )
        } else {
            return null;
        }
   }

   getIcon = (signatureId) =>  {
      // const {subject} = this.props;
      // const document =  _.filter(subject.subjectDocumentSignatures, (doc) => doc.id === signatureId ); 
      // if(document.length > 0 ){
         return <AntDesign name='checkcircle' size={fontType('StatusIcon')} color={Colors.checkCircle} /> 
      // }
      // return <Icon  name="arrow-forward" style={{ color: Colors.buttonwithbg, fontSize:wp('4%')  }} />;
   }

//    onDocumentSelect = (documentPageID) => {
//       const {subject, signType, navigation} = this.props;
//       const signature = getConsentChapter(this.context.languages, subject.language, 'SIGNATURE');
//       let documentSignature =  _.filter(signature.documentSignatures, { 'signatureType': signType , 'documentId':documentPageID })[0];
//       {(documentSignature.isRequired == true || documentSignature.isReconsentRequired == true) ? 
//          navigation.navigate('SubjectDocumentSignScreen', { documentSignatureId: documentSignature.id, disableTapToSignIfNoSigns: false }) :
//          navigation.navigate('SubjectDocumentSignScreen', { documentSignatureId: documentSignature.id, disableTapToSignIfNoSigns: true})
//       }
//    }


   render() {
      const { documentSignatures, 
              navigation, menuVisible,
              handleModalClose, submitConsentData,
              disableSubmit, subject, isRegistered, loading, subjectDocumentSignatures,
              screenProps: { t }, documentPages, enableSubmit } = this.props;
      return (
        
         <Container>
            <ChapterTitle chapterType="SIGNATURE" chapterTitle={t('DocsList')} displayComments={false}/>
            <Content>
            <MyLoader  active={loading}/>
               <FlatList
                  data={subjectDocumentSignatures}
                  progressViewOffset={30}
                  // keyExtractor={item => item.key}
                  renderItem={({ item, index }) => (
                     <List>
                        <ListItem button 
                           // onPress={() => {navigation.navigate('SubjectDocumentSignScreen', { documentSignatureId: item.id })}}
                           // onPress={() => this.onDocumentSelect(item.id)}
                           >
                           <Body>
                              {this.getSignaturesData(item.id)}
                           </Body>
                           <Right>

                               {this.getIcon(item.id)}

                           </Right>
                        </ListItem>
                     </List>
                  )}
               />
            </Content>
            <Footer style={styles.Footer}>
               <FooterTab>
               {enableSubmit ? 
                  <Button full
                     style={[styles.buttonSbmt, {backgroundColor: loading ? Colors.buttondisabled : Colors.buttonwithbg}]}
                     disabled={loading}
                     onPress={() => { submitConsentData()}}   
                  >
                  <View style={{flexDirection:'row'}}>
                     <Text style={styles.buttonTxt}>{t('Submit')}</Text>
                  </View>
                  </Button>
                  :
                  <Button full
                     style={[styles.buttonSbmt, {backgroundColor: loading ? Colors.buttondisabled : Colors.buttonwithbg}]}
                     disabled={loading}
                     onPress={() => {navigation.navigate('CompletedScreen')}}   
                  >
                  <View style={{flexDirection:'row'}}>
                     <Text style={styles.buttonTxt}>{t('OK')}</Text>
                     {/* {loading && <ActivityIndicator animating={true} size="large" style={{marginLeft:wp('5%')}}
                     color={Colors.aiwithbg} />} */}
                  </View>
                  </Button>}
               </FooterTab>
            </Footer>
          
         </Container>
      )
   }

  
}

var styles = StyleSheet.create({
   buttonSbmt: {
      backgroundColor: Colors.buttonwithbg,
   },
   buttonTxt: {
      color: Colors.textColorwithbg,
      textAlign: 'center',
      fontWeight: '200',
      fontSize: fontType('ButtonTxt'),
      fontFamily:'Inter_Bold'

   },
   Footer: {
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      margin:10
   },
   documentTxt: {
      color: Colors.text,
      fontSize: fontType('BasicFont'),
      fontFamily:'Inter'
   }

});
