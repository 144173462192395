import Dialog, {
  DialogContent,
  DialogTitle,
  ScaleAnimation,
} from "react-native-popup-dialog";
import React, { Component, createRef } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  View,
  ActivityIndicator,
  Platform,
  TouchableOpacity,
  Dimensions,
  Linking,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { Colors } from "../../constants/ui/colorScheme";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import api from "../../utils/api";
import authapi from "../../utils/authApi"
import HtmlParserEg from "../htmlView/htmlparserEg";
import { fontType } from "../../styles/font";
import { constStyles, chapterButtonStyles } from "../../styles/constStyles";
import FormikInput from "./FormikInput";
import { ScrollView } from "react-native-gesture-handler";
import { fromBinary } from "uuid-js";
import showToast from "../../utils/toast";
import NoEmailPopUp from "../../utils/NoMailPopUp";
import { Card } from "native-base";
import NoRemoteConsentMailPopUp from "../../utils/NoRemoteConsentMailPopUp";
import DeclarationFormField from "../documents/DeclarationFormFields";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import Constants from "../../utils/constants";
import Header from "../common/header";
import constants from "../../utils/constants.js";
import { getHeaderTitle } from "../../containers/NavigationScreens.js";
import AuthorizationPopUp from "./AuthorizationPopup.js";
import { SubjectStatus } from "../common/constants.js";
const { ContextProperties } = constants

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window");

class DialogBox extends Component {
  state = {
    isLoading: false,
    isAuthorized: true,
    errorMessage: "",
    isNoMail: false,
    currentUser: "",
  };
  componentDidMount() {
    const { currentUserType } = this.props;
    switch (currentUserType) {
      case 1:
        this.setState({
          currentUser: "Subject",
        });
        break;
      case 2:
        this.setState({
          currentUser: "LAR",
        });
        break;
      case 3: 
        this.setState({
          currentUser: "Clinician",
        });
        break;
      case 4:
        this.setState({
          currentUser: "Witness",
        });
        break;
    }
  }
  authorizeClinican = async (values) => {
    const {
      selectedDocumentSignatureId,
      closePopup,
      selectedUser,
      subjectId,
      subjectConsentId,
      subjectDocumentSignatureId,
      docId,
      navigation,
      siteId,
      studyId
    } = this.props;
    let validation = this?.child ? this.child.updateValidation() : true;
    if (validation) {
        try {
        const context = {
          studyId:studyId,
          siteId:siteId,
          subjectId:subjectId,
          subjectDocumentSignatureId: subjectDocumentSignatureId,
          subjectConsentId: subjectConsentId,
          productType:'ECONSENT',
          featuretype:'CLINICIAN_SIGNATURE',
        }
        const authVerificationRequest = {
          username: values.username,
          password:values.password,
          context:context,
        }
        this.setState({ isLoading: true });
        const res = await authapi.post(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/users/${selectedUser?.id}/auth-checks`, authVerificationRequest);
        this.setState({ isLoading: false, isAuthorized: true });
        const authorized = true;
        navigation.navigate("Admin", {
          screen: "CounterSignatureScreen",
          fullName: selectedUser.fullName,
          documentId: docId,
          documentSignatureId: selectedDocumentSignatureId
       });
      } catch (error) {
        this.catch(error);
      }
    }
  }
  authorizeUser = async (values) => {
    const {
      selectedDocumentSignatureId,
      navigation,
      documentID,
      closePopup,
      selectedUser,
      subject,
      subjAuth,
      currentUserType,
    } = this.props;

    let validation = this?.child ? this.child.updateValidation() : true;
    if (validation) {
      if (subjAuth) {
        try {
          const sub = {
            id: subject.id,
            userName: values.username,
            loginPassword: values.password,
          };

          const userType = currentUserType;
          const data = {
            subject: sub,
            userType: userType,
          };
          this.setState({ isLoading: true });
          const res = await api.post(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subject.id}/authorize`, data);
          this.setState({ isLoading: false, isAuthorized: true });
          const authorized = true;
          closePopup(authorized);
        } catch (error) {
          this.catch(error);
        }
      } else {
        this.setState({ isLoading: true });

        const organizationCode =
          window.sessionStorage.getItem("organizationCode");

        Linking.openURL(
          Constants.ADMIN_PORTAL_BASE_URL +
            `/api/${organizationCode}/organizations/${organizationCode}/documents/` +
            documentID +
            "/documentSignatures/" +
            selectedDocumentSignatureId +
            "/verifyLogin"
        );

        // try {
        //   const res = await api.post("/document/counterSignature/authorize", { clientUser }, config);
        //   closePopup();
        //   this.setState({
        //     isLoading: false,
        //     isAuthorized: true
        //   });
        //   navigation.navigate("CounterSignatureScreen", {
        //     fullName: res.data.fullName,
        //     documentId: documentID,
        //     documentSignatureId: selectedDocumentSignatureId
        //   });
        // } catch (error) {
        //   this.catch(error);
        // }
      }
    }
  };

  validateFields = (values) => {
    if (values.username != "" && values.password != "") {
      return false;
    } else {
      return true;
    }
  };

  catch = (error) => {
    const { t } = this.props;
    this.setState({ isLoading: false });
    if (error.response) {
      if (error.response.status == 401 || error.response.status == 400) {
        this.setState({
          isAuthorized: false,
          errorMessage: `${
            t ? t("InvalidUsrName") : "Invalid username or password."
          }`,
        });
      } else if (error.response.status == 423) {
        this.setState({
          isAuthorized: false,
          errorMessage: `${t ? t("UserLocked") : "User locked."}`,
        });
      } else {
        this.setState({
          isAuthorized: false,
          errorMessage: `${
            t ? t("SomethingWentWrong") : "Something went wrong."
          }`,
        });
      }
    } else {
      this.setState({
        isAuthorized: false,
        errorMessage: `${t ? t("NetworkErr") : "Network Error."}`,
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 3000);
    }
    console.log(error);
  };

  sendOtp = async (otpType) => {
    const {
      navigation,
      data,
      subject,
      closePopup,
      subDocSignNavProps,
      signatureType,
      selectedSubject,
      t,
      currentUserType,
    } = this.props;
    const obj = {
      subjectId: subject.id,
      otpType: otpType,
      userType: currentUserType,
      otp: {}
    };
    if (currentUserType == 2 && selectedSubject.larEmail == "") {
      this.setState({ isNoMail: true });
    } else if (currentUserType == 4 && selectedSubject.witnessEmail == "") {
      this.setState({ isNoMail: true });
    } else if (selectedSubject.email) {
      try {
        this.setState({ loading: true });
        const res = await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subject.id}/otps`
          , obj);
        closePopup(false);
        showToast("OTP has been sent to Email.", "success", 2000);
        let loUserName = "";
        if (currentUserType == 1) {
          loUserName = selectedSubject.userName;
        } else if (currentUserType == 2) {
          loUserName = selectedSubject.larUserName;
        } else if (currentUserType == 4) {
          loUserName = selectedSubject.witnessUserName;
        }
        this.setState({ loading: false });
        const validationObj = JSON.stringify({
          otpId: res.data.id,
          otpType: otpType,
          subjectId: subject.id,
          from: "SUBJCET_AUTH_POPUP",
          subDocSignNavProps: subDocSignNavProps,
          isRemoteConsent: subject.isRemoteConsent,
          signatureType: signatureType,
          userName: loUserName,
        });
        navigation.navigate("SubjectAuthStack", {
          screen: "SubjectValidateOtpPage",
          params: {
            validationObj: validationObj,
          },
        });
      } catch (error) {
        console.log(error);
        this.setState({ loading: false });
        if (error.response) {
          this.setState({
            isAuthorized: false,
            errorMessage: "Failed to send OTP to Email.",
          });
        } else {
          this.setState({
            isAuthorized: false,
            errorMessage: `${t ? t("NetworkErr") : "Network Error."}`,
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      }
    } else {
      this.setState({ isNoMail: true });
    }
  };

  toggleIsNoMailPopUp = () => {
    this.setState((prevState) => ({
      ...prevState,
      isNoMail: !prevState.isNoMail,
    }));
  };

  getUserTitle = () => {
    const { currentUserType, t } = this.props;
    if (currentUserType == 1) {
      return t("SubjAuthorization");
    } else if (currentUserType == 2) {
      return t("LarAuthorization");
    } else if(currentUserType == 3) {
      return t("ClinicianAuthorization");
    }else if (currentUserType == 4) {
      return t("WitnsAuthorization");
    }
  };
  onOk = () => {
    this.setState({ isNoMail: false });
  };

  setScrollRef = (position) => {
    this.scrollRef.scrollTo({ x: 0, y: position });
  };

  validateCredentials = (values) => {
    const {
      selectedDocumentSignatureId,
      navigation,
      documentID,
      closePopup,
      selectedUser,
      subject,
      subjAuth,
      currentUserType,
    } = this.props;

    const organizationCode = window.sessionStorage.getItem("organizationCode");
    Linking.openURL(
      Constants.ADMIN_PORTAL_BASE_URL +
        `/api/${organizationCode}/organizations/${organizationCode}/documents/` +
        documentID +
        "/verifyLogin"
    );
  };

  render() {
    const {
      showDialog,
      closePopup,
      selectedDocSignDeclaration,
      subjAuth,
      t,
      declarationFormFields,
      docId,
      docSignId,
      storeDeclarationFormData,
      formDataList,
      currentUserType,
      selectedUser,
      selectedDocumentSignatureId,
      selectedSubject,
    } = this.props;
    const { isLoading, isAuthorized, errorMessage, isNoMail, currentUser } =
      this.state;
    const declaration = selectedDocSignDeclaration;
    const encodeDec = declaration;
    return (
      <View style = {{flex:1}}>
        <View style = {{marginTop: 5}}>{getHeaderTitle([],false,false,{}, this.getUserTitle())}</View>
              <View style={{paddingVertical: 5}}>
                  <ScrollView 
                   ref={(c) => {
                    this.scrollRef = c;
                  }}
                  style={{height:'auto',maxHeight:150}}>
                  <DeclarationFormField
                    declarationFormFields={declarationFormFields}
                    documentSignatureId={docSignId}
                    t={t}
                    documentId={docId}
                    storeDeclarationFormData={storeDeclarationFormData}
                    formDataList={formDataList}
                    onRef={(ref) => (this.child = ref)}
                    setScrollRef={this.setScrollRef}
                    fromPopup={true}
                  />
                  </ScrollView>
                {/* <ScrollView> */}
                {/* {declaration ? 
                  <HtmlParserEg
                    currentDoc={encodeDec}
                    isDeclaration={true}
                    isDialogBox={true}
                  /> : null } */}
                {/* </ScrollView> */}
              </View>
              <View style={{ marginTop: 10, flexDirection: "row" }}>
                {/* <View style={{ flex: 1 }}></View> */}

                {subjAuth &&(
                  <View  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "stretch",
                    margin: 5,
                  }}>
                  <AuthorizationPopUp
                  isAuthorized={isAuthorized}
                  errorMessage={errorMessage}
                  authorizeUser={(values) => this.authorizeUser(values)}
                  userNameHeader={"Username"}
                  passwordHeader={"Password"}
                  userNamePlaceHolder={"EnterUsername"}
                  passwordPlaceHolder={"EnterPassword"}
                  cancelButton={"Cancel"}
                  submitButton={"Submit"}
                  sendOtp={(bool) => this.sendOtp(bool)}
                  t={t}
                  subjAuth={subjAuth}
                  isLoading={isLoading}
                  closePopup={() => closePopup()}
                  validateFields={(values) => this.validateFields(values)}
                  />
                    <NoRemoteConsentMailPopUp
                      isNoMail={isNoMail}
                      onOk={this.onOk}
                      currentUser={currentUser}
                    />
                  </View>
                )} 
                {currentUserType === 3 ? 
                <View  
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "stretch",
                }}>
                  {!_.isEqual(selectedSubject.status, SubjectStatus.NEEDS_COUNTER_SIGNATURE) && _.isEqual(currentUserType, 3) &&
                <View style={styles.msgContainer}>
                  <Text style={styles.msgText}>{t('CounterSignRequiredSignaturesPending')}</Text>
                </View>
              }
                <View  
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "stretch",
                  margin: 5,
                }}
                pointerEvents={!_.isEqual(selectedSubject.status, SubjectStatus.NEEDS_COUNTER_SIGNATURE) && _.isEqual(currentUserType, 3)? "none": "auto"}
                >
                <AuthorizationPopUp
                 isAuthorized={isAuthorized}
                 errorMessage={errorMessage}
                 authorizeUser={(values) => this.authorizeClinican(values)}
                 userNameHeader={"UserId"}
                 passwordHeader={"Password"}
                 userNamePlaceHolder={"EnterUserId"}
                 passwordPlaceHolder={"EnterPassword"}
                 cancelButton={"Cancel"}
                 submitButton={"Verify"}
                 sendOtp={(bool) => this.sendOtp(bool)}
                 t={t}
                 isLoading={isLoading}
                 closePopup={() => closePopup()}
                 validateFields={(values) => this.validateFields(values)}
                /> 
                  <NoRemoteConsentMailPopUp
                      isNoMail={isNoMail}
                      onOk={this.onOk}
                      currentUser={currentUser}
                    />
                </View>
                </View>
                :!subjAuth &&(
                  <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
                    <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "space-between",
                    paddingVertical: 6,
                    paddingHorizontal: 8,
                    alignItems: "center",
                    marginLeft: 20,
                  }}
                >
                  <View style = {{ justifyContent: 'center', marginBottom: 20, flex: 1}}>
                    {getHeaderTitle([],false,false,{},'User Authorization')}
                  </View>
                  <View>
                    <TouchableOpacity
                      onPress={() => {
                        this.setState({ isAuthorized: true });
                        //this for subjAuth close
                        closePopup(false);
                      }}
                    >
                      <Icon
                        name="close"
                        color={"#616161"}
                        size={fontType("ChapterIcon")}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                    <TouchableOpacity
                      disabled={false}
                      style={[
                        constStyles.buttonStyle,
                        {
                          alignSelf: "center",
                          margin: 3,
                          // backgroundColor: 'grey'
                          backgroundColor: Colors.buttonwithbg,
                        },
                      ]}
                      onPress={() => this.authorizeUser()}
                    >
                      <Text style={constStyles.buttonTxt}>
                        {"Validate Credentials"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

                {/* <View style={{ flex: 1 }}></View> */}
              </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  buttonSubmit: {
    margin: wp("1%"),
    borderRadius: wp("3%"),
    minWidth: wp("16%"),
  },
  txtInput: {
    borderBottomWidth: 1,
    marginTop: 5,
    fontFamily: "Inter",
  },

  skipBtn: {
    borderColor: Colors.buttonwithbg,
    margin: wp("1%"),
    borderRadius: wp("3%"),
    minWidth: wp("16%"),
  },

  btnTxt: {
    color: Colors.textColorwithbg,
    marginLeft: wp("3%"),
    fontSize: fontType("ButtonTxt"),
    fontFamily: "Inter_Bold",
  },
  title: {
    color: "red",
  },
  msgContainer: {alignSelf: 'center', marginTop: 20, marginBottom: 20},
  msgText: {
    fontFamily: 'Inter',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 16,
    marginBottom: 20,
    color: Colors.error,
  },
});

export default DialogBox;
