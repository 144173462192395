import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { View, Platform,  ActivityIndicator } from "react-native";
import showToast from '../utils/toast';
import { Colors } from '../constants/ui/colorScheme';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { storeSelectedClientUser } from "../actions/clientUser";
import { saveDeviceSNoSuccess } from "../actions/consentToken";
import { setOrganizationName } from "../actions/clientUser";
import { updateSession } from "../actions/session";
import PropTypes from 'prop-types'


const WebAppLandingPage = (props) => {

    const [loading, setLoading ] = useState(false);

    const generateDeviceSerialNumber = async () => {
        const { saveDeviceSNoSuccess } = props;

        try{
            const siteDevice = {
                "deviceName": Constants.deviceName,
            }
            const res = await api.post("/generateDeviceSerialNo", siteDevice );
            
            saveDeviceSNoSuccess(res.data);

        } catch(err) {
            console.log(err);
        }
    }

    const getUserData = async () => {
        const { navigation, form, storeSelectedClientUser, deviceNo, setOrganizationName,
            screenProps: { validateSession }, updateSession: loUpdateSession } = props;

            const loginRequestId = props.navigation.getParam('loginRequestId');
            window.sessionStorage.setItem('loginRequestId', loginRequestId);

        try {
            setLoading(true);
            api.defaults.headers.common.requestId = loginRequestId;
            const res = await api.post("/login", null);
            api.defaults.headers.common.Authorization = res.headers['authorization'];
            
            const authToken = res.headers['authorization'];
            // console.log('Token:------:', res.headers.authorization)
  

            if(Platform.OS == 'web') {
                window.sessionStorage.setItem('token', authToken);
            }
            
            validateSession(true);
            
            storeSelectedClientUser(res.data);
            loUpdateSession({primaryClient: res.data?.primaryClient?.id, site:res.data?.client?.id, primaryOrgCode: res.data?.primaryClient?.organizationCode, userType: 3})
            window.sessionStorage.setItem('organizationCode', res.data.primaryClient.organizationCode);

            if(deviceNo == '' && Platform.OS != 'web') {                         
                generateDeviceSerialNumber();
            }

            setLoading(false);
            if (res.data.client && res.data.client.clientType !== "Site") {
                navigation.navigate('Admin', { screen: 'AccessNotPermitted' });
            } else if (res.data.clientOrganizations.length > 1) {
                navigation.navigate('Admin', { screen: 'OrgListPage' });
            } else {
                setOrganizationName(res.data.clientOrganizations[0].name, res.data.clientOrganizations[0].id)
                navigation.navigate('Admin', { screen: 'StudyListPage' });
            }

        } catch(err) {
            setLoading(false);

            console.log(err);

            delete api.defaults.headers.common.Authorization;

            const result = err.response ? err.response.status : 'NetworkError';
            if (result === 401) {
                showToast("Invalid username or password.", 'danger', 4000);
            } else if (result === 423) {
                showToast("User locked.", 'danger', 4000);
            } else if (result === 500) {
                showToast("Something went wrong.", 'danger', 4000);
            } else {
                showToast('Network Error.', 'danger', 2000);
            }
        }

    }
    
    useEffect(() => {
        getUserData();
    }, []);
    return (
        <View>
            {loading ? <ActivityIndicator size="large" color={Colors.ActivityIndicator} animating={true} hidesWhenStopped={true} /> : null}
        </View>
    );
}

WebAppLandingPage.defaultProps = {
    navigation: {},
    form: {},
    deviceNo: '',
    screenProps: { validateSession: () => {} }
};

WebAppLandingPage.propTypes = {
    navigation: PropTypes.object,
    form: PropTypes.object,
    storeSelectedClientUser: PropTypes.func.isRequired,
    deviceNo: PropTypes.string,
    setOrganizationName: PropTypes.func.isRequired,
    updateSession: PropTypes.func.isRequired,
    screenProps: PropTypes.shape({
        validateSession: PropTypes.func
    })
};
const mapStateToProps = state => ({
    deviceNo: state.consentTokenData.deviceNo,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeSelectedClientUser,
        saveDeviceSNoSuccess,
        setOrganizationName,
        updateSession
    },
    dispatch,
);

export default connect( mapStateToProps, mapDispatchToProps )(WebAppLandingPage);