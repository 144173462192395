export default {
  WhatToExpect: "À quoi s'Attendre",
  WhatToDescription:
    "Cette application peut vous aider à mieux comprendre l'étude, de sorte que vous pouvez décider si vous voulez participer.",
  WhatToFooter:
    "Vous recevrez une copie signée et datée du formulaire de consentement éclairé à la fin du processus de consentement éclairé.",
  NextChapter: "PROCHAIN CHAPITRE",
  Next: "PROCHAINE",
  PreviousChapter: "CHAPITRE PRÉCÉDENT",
  Previous: "PRÉCÉDENTE",
  Continue: "CONTINUER",
  Help: "Aider",
  Close: "FERMER",
  Hint: "ASTUCE",
  HintText:
    "Vue de la partie de consentement éclairé que cette question des références et de répondre à nouveau.",
  Question: "Question",
  Submit: "SOUMETTRE",
  Congratulations: "Félicitations!",
  Cong1:
    "Vous avez terminé l'Examen des Connaissances. Si vous avez des questions avant de décider si vous souhaitez participer, veuillez vous adresser à l'étude de l'enquêteur ou le personnel avant de poursuivre le processus de signature.",
  Cong2:
    'Si vous souhaitez participer à l\'étude, touchez le bouton "Continuer" ci-dessous pour commencer le processus de signature.',
  Cong3:
    "Si vous avez décidé de ne pas participer à l'étude, veuillez Arrêter Ma Participation bouton ci-dessous.",
  StopMyPart: "ARRÊTER MA PARTICIPATION",
  WhoInfC: "Qui est la signature de ce consentement éclairé ?",
  SubjInfc: "Je vais signer le consentement éclairé.",
  LarInf:
    "Mon représentant légal devra signer le formulaire de consentement éclairé.",
  SignedBy: "Signé Par",
  SignedOn: "Signé Sur",
  TapToSign: "APPUYEZ SUR POUR SIGNER",
  SignWarning: "VEUILLEZ VOUS CONNECTER POUR CONTINUER.",
  OK: "OK",
  Clear: "CLAIRE",
  Cancel: "ANNULER",
  ThankYou:
    "Merci d'avoir signé ce consentement électronique. Une fois toutes les signatures obtenues, vous recevrez une copie de votre formulaire de consentement éclairé signé. Si vous avez des questions, veuillez contacter votre site.",
  ReturnTab:
    "Veuillez retourner l'appareil à revenir à l'étude du coordonnateur ou de l'enquêteur.",
  DocsToSign: "Documents à Signer",
  DocsList: "Liste des documents",
  EndPart: "Fin à votre participation à l'étude ?",
  SignHere: "Signer Ici",
  SelectWarn: "Merci de sélectionner au moins une option. ",
  FirstNameWarn: "Prénom ne peut pas être vide.",
  LastNameWarn: "Nom de famille ne peut pas être vide.",
  DOBWarn: "Date de Naissance ne peut pas être vide.",
  DoneSigning: "FAIT",
  Register: "REGISTRE",
  SelectDate: "Sélectionnez la date",
  DidNotSignConsent: "N'a pas signé le Consentement",
  ConsentSigned: "Consentement Signé",
  SubjectSignature: "Sous Réserve De La Signature",
  GoBackToDoc: "RETOUR AU DOCUMENT",
  GenderWarn: "Le sexe ne peut pas être vide.",
  PhoneNoWarn: "Numéro de téléphone ne peut pas être vide.",
  RegPlacHolder: "Appuyez ici pour taper",
  StopPartFailed:
    "Quelque chose s'est mal passé. S'il vous plaît essayer à nouveau plus tard.",
  LARsign: "LAR Signature",
  LARName: "Nom du représentant légalement autorisé, en entier",
  LARRelation:
    "Description / relation du tuteur ou du représentant légalement autorisé",
  TypeLARName: "Appuyez ici pour taper le nom",
  TypeLARRelation: "Appuyez ici pour taper la relation",
  FillWarn: "S'il vous plaît remplir ce champ.",
  EnterHere: "Entrer ici",
  RegistrationForm: "Formulaire d'inscription",
  SignatureChapter: "Signature",
  AreYouSure: "Êtes-vous sûr?",
  Confirm: "Confirmer",
  PlaceHolderText: "Appuyez ici pour taper",
  SaveFail: "Échec de l'enregistrement.",
  ValidPhn: "S'il vous plaît entrer un numéro de téléphone valide.",
  NoData: "Pas de contenu.",
  SelectOption: "Veuillez sélectionner au moins une option.",
  DocFieldWarning: "Veuillez remplir tous les champs obligatoires.",
  PleaseScroll:
    "Veuillez faire défiler vers le bas et lire le document complètement avant de continuer.",
  IRBApprovedDate: "Date approuvée par la CISR",
  Version: "Version",
  SessionExpired: "La session a expiré! Veuillez vous reconnecter.",
  ConsentSubmitSuccess: "Consentimiento presentado con éxito.",
  ConsentSubmitFail:
    "Error en el envío del consentimiento. Vuelva a intentarlo.",
  NetworkErr: "Erreur réseau.",
  Username: "Nom d'utilisateur",
  Password: "Mot de passe",
  EnterPassword: "Entrer le mot de passe",
  EnterUsername: "Saisissez votre nom d'utilisateur",
  InvalidUsrName: "Nom d'utilisateur ou mot de passe invalide.",
  UserLocked: "Utilisateur verrouillé.",
  SomethingWentWrong: "Un problème est survenu.",
  SubjAuthorization: "Autorisation du sujet",
  LarAuthorization: "Autorisation LAR",
  WitnsAuthorization: "Autorisation de témoin",
  SubjIniWarn: "Veuillez entrer vos initiales ici.",
  Subdec: "Veuillez écrire votre signature dans l'espace ci-dessous.",
  Initials: "Initiales",
  RadioInitialsVal:
    "Veuillez sélectionner une option et saisir l'initiale à côté de l'option que vous avez sélectionnée.",
  Skip: "sautiller.",
  SkipFail: "Je n’ai pas sauté.",
  DateOfBirth: "Entrer date de naissance",
  WitnessSign: "Signature du témoin",
  SubjectList: "Liste des sujets",
  CancelFlow:
    "Merci, veuillez remettre l'appareil au représentant de la clinique.",
  OfficeThankYou:
    "Merci, veuillez remettre l'appareil au représentant de la clinique afin de finaliser le consentement.",
  ReturnToSiteStaff:
    "Pour revenir à la liste des sujets, veuillez confirmer que vous êtes le clinicien et cliquez sur le bouton de retour à la liste des sujets",
  CommentReview: "Examen des commentaires",
  CommentsPending:
    "Vous avez des commentaires en suspens suivis dans notre système. Le personnel du site a été informé et vous contactera pour discuter de vos questions ou vous pouvez les contacter à",
  ConsentIncomplete:
    "Votre consentement ne peut pas être complété tant que tous les commentaires n’ont pas été résolus. Vous recevrez un e-mail lorsque vous pourrez poursuivre le processus de consentement.",
  AllCommentsAddressed: "Tous les commentaires ont été traités.",
  ContinueWithSubject:
    "Veuillez donner l'appareil au sujet pour poursuivre le processus de consentement éclairé.",
  Comments: "commentaires",
  CommentsEmailSent:
    "Un e-mail a été envoyé au participant, veuillez le contacter pour compléter le consentement éclairé.",
  AllCommentsMarkedResolved: "Tous vos commentaires ont été marqués résolus.",
  AnyQuestionsText:
    "Si vous avez des questions avant de décider si vous souhaitez participer, veuillez demander à l'investigateur de l'étude ou au personnel avant de procéder au processus de signature.",
  ContinueParticipation:
    "Si vous souhaitez participer à l'étude, veuillez appuyer sur le bouton Continuer ci-dessous pour commencer le processus de signature.",
  WrongAnswer:
    "La réponse que vous avez sélectionnée est incorrecte, veuillez réessayer",
  PrintNSign:
    "Vous avez sélectionné imprimer et signer. Ce document sera imprimé avec un espace réservé aux signatures manuscrites. Veuillez vous assurer que le participant a examiné le contenu du consentement électronique et a eu l'occasion de discuter de toute question.",
  VerifyPrintNSign:
    'Vous avez choisi de vérifier que le consentement a été signé en dehors du système électronique. Veuillez déposer la copie papier signée et vous assurer que le participant en reçoive une copie.Une fois que vous avez sélectionné "Soumettre le consentement", cela ne peut pas être annulé',
  PrintNSignFlow: "Cette étude nécessite une signature manuelle.",
  UnverifyPrintNSign: `Vous avez choisi d'annuler la vérification de ce document. Lorsque vous cliquez sur OK, le document ne sera pas vérifié et vous pourrez imprimer le document. Vous pourrez revérifier le document. Tous les documents doivent être vérifiés pour soumettre le consentement. Une fois que vous avez sélectionné le bouton "soumettre le consentement", ce champ ne sera pas modifiable, le document restera vérifié.`,
  RemotePrintNSignError:
    "Ce site nécessite des signatures manuelles sur le consentement éclairé, vous ne pouvez pas envoyer le consentement par e-mail.",
  KnowledgeReviewCompletion: "Vous avez terminé l'examen des connaissances.",
  InOfficeCommentsPending:
    "Vous avez des commentaires en suspens suivis dans notre système. Veuillez remettre l'appareil au personnel du site pour discuter et résoudre les problèmes en suspens.",
  CounterSignRequiredSignaturesPending:
    "Tous les signataires requis n'ont pas encore signé ce document. Veuillez revenir à cet écran une fois que toutes les signatures requises ont été collectées.",
  Logout: "DÉCONNEXION",
  StopConsentPopup:
    "Êtes-vous sûr de vouloir arrêter le consentement et déconnecter le clinicien du système ? Une connexion sera nécessaire pour que vous puissiez continuer à donner votre consentement.",
  Yes: "Oui",
  No: "Non",
  ChangingDataLabel: "Vous modifiez les données suivantes :",
  ReasonForChangeLabel: "Veuillez indiquer la raison du changement",
  ReasonForChangeWarning: "La raison du changement est requise.",
  SaveChanges: "ENREGISTRER LES MODIFICATIONS",
  ReasonForChangeNote:
    "Remarque : La raison saisie pour le changement s’appliquera à tous les changements. Si vous souhaitez saisir différents motifs de changement, cliquez sur le X pour fermer cette fenêtre et effectuer les modifications dans des transactions distinctes. N’incluez pas de données protégées dans le champ Raison de la modification.",
  ReasonForChangeDetails: "Détails de la raison du changement",
  ReasonForChange: "Raison du changement",
  Verify: "VÉRIFIER",
  UserId: "Identifiant utilisateur",
  EnterUserId: "Entrez UserId",
  ClinicianAuthorization: "Autorisation du clinicien",
  SubmitConsent: "Soumettre le consentement",
  AccessNotPermitted: "Accès non autorisé",
  ApologyForNotPermit:
    "Nous nous excusons, mais votre rôle actuel ne vous permet pas d’accéder à cette application.",
  ContactAdminstrator:
    "Pour obtenir de l’aide, veuillez contacter la cheffe de projet de votre étude.",
  UnAuthorizedMessage: "Vous n'êtes pas autorisé à voir cette page.",
  ProccedFail: 'Échec de la procédure.',
};
