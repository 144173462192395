import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Icon } from 'native-base';
import { tintColor, getHeaderTitle, headerStyle, headerTitleStyle } from '../containers/NavigationScreens';
import Header from '../components/common/header'


export const adminAuthStackDefaultNavigationOptions = ({ navigation, screenProps: { languages, locale, resetSession, navigateToUsersList,isSessionActive,isLoggedin},  }) => {
    if(isSessionActive==false && isLoggedin==false && navigateToUsersList==true ){
      resetSession();
      navigation.original.reset({
        index: 0,
        routes: [
          {
            name: 'AdminAuthStack',
          },
        ],
      })
    }
    return {
        headerBackTitleVisible: false,
        // headerTitle: <Text style={{flex: 1,fontSize:fontType('BasicFont'), textAlign: 'center',color:'white', fontFamily:'Inter'} }></Text>,
        gesturesEnabled: false,
        headerStyle: {
        ...headerStyle
        },
        headerTitleStyle: { 
          ...headerTitleStyle
         },
        headerTintColor: tintColor,
        headerTitle: null,
        headerLeft: () => <Header canShowBackIcon={false} title={null} navigation = {navigation}/>
    }
  }

export const adminStackDefaultNavigationOptions = ({ 
    screenProps:
        { languages,studySite,
        handleHeaderMenuDialoge,
        resetSession,setNavigation , locale,navigateToUsersList,isSessionActive,isLoggedin, t}, navigation }) => {
    if(isSessionActive==false && isLoggedin==false && navigateToUsersList==true ){
      resetSession();
      navigation.original.reset({
        index: 0,
        routes: [
          {
            name: 'AdminAuthStack',
          },
        ],
      })
    }
    return {
      headerBackTitleVisible: false,
      headerTitle: () => (getHeaderTitle(languages,false,false, studySite)),
      headerTitle: '',
      gesturesEnabled: false,
      headerStyle: {
        ...headerStyle
      },
      headerTitleStyle: { ...headerTitleStyle },
      headerTintColor: tintColor,
      headerLeft: () => <Header canShowBackIcon={false} title={null} navigation = {navigation}/>
    }
  }

export const consentStackDefaultNavigationOptions = ({ screenProps: {languages,studySite, handleHeaderMenuDialoge, setNavigation, locale,resetSession, navigateToUsersList,isSessionActive,isLoggedin }, navigation }) => {
    if(isSessionActive==false && isLoggedin==false && navigateToUsersList==true ){
      resetSession();
      navigation.original.reset({
        index: 0,
        routes: [
          {
            name: 'AdminAuthStack',
          },
        ],
      })
    }
    return {
      headerBackTitleVisible: false,
      headerLeft: navigation.state.routeName=='SubjectDocumentSignScreen'? '': null,
      gesturesEnabled: false,
      headerTitle:() =>  (getHeaderTitle(languages,true,true, studySite)),
      headerStyle: {
        ...headerStyle,
      },
      headerTitleStyle: { ...headerTitleStyle },

      headerTintColor: tintColor,
      headerLeft: () => <Header canShowBackIcon={false} title={navigation.state.routeName=='SubjectDocumentSignScreen'? '': null} navigation = {navigation}/>,
      headerRight: () => (
        <TouchableOpacity
        onPress={() => { 
          setNavigation(navigation);
          handleHeaderMenuDialoge();
        }}
          style={{ width: 40 }}
        >
          <Icon name='menu' style={{ fontSize: 35, color: '#3a35414d' }}
          />
        </TouchableOpacity>
      )
    };
    
  }

  export const wrapScreenAndNavigationOptions = (screen, navigationOptions) => {
      return {
          screen,
          navigationOptions
      }
  }