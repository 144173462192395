import React, { useEffect, useState } from 'react';
import ChapterTitle from '../chapterTitle/ChapterTitle';
import { View, Text, StyleSheet, ActivityIndicator, Dimensions } from 'react-native';
import { Colors } from '../../constants/ui/colorScheme';
import {  Footer } from 'native-base';
import { chapterButtonStyles, constStyles } from '../../styles/constStyles';
import TryalButton from '../common/button';
import { ButtonTypes } from "../common/constants";
import { useDispatch, useSelector } from 'react-redux';
import { getCommentsCount, retrieveComments } from '../../actions/comments';
import SubjectCommentsResolvedScreen from './subjectCommentsResolvedScreen';
import SubDocsPage from '../../containers/SubDocsPage';
import { isKnowledgeReviewChapterPresent } from '../../utils/consentData';
import { CommonActions } from '@react-navigation/native';
import { inOfficeLogout } from '../../actions/subject';
const {height: SCREEN_HEIGHT} = Dimensions.get('window')
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp,
  } from 'react-native-responsive-screen';
import {styles} from '../../components/congratulations/styles/congratulationStyle';

const CommentsReviewStyles= StyleSheet.create({
    containerStyle:{
        display: 'flex',
        backgroundColor: Colors.background,
        flex: 1,
    },
    bodyStyle: {
        padding: 10
    },
    textStyle: {
        textAlign: 'center',
        fontFamily: 'Inter',
        marginBottom: 20,
        fontSize: 14,
    }
});

const CommentsReview = (props) => {
    const { screenProps, navigation } = props;
    const { isRemoteConsent, id, isKnowledgeReviewDone } = useSelector(state => state.subject)
    const { selectedSubject, studySite, subjectRemoteConsentDto } = useSelector(state => state)
    const { email, phoneNo } = useSelector(state => state.clientUserDetails)
    const [count, setCount] = useState({unresolvedCount: 0, loading: false});
    const [commentCount, setCommentCount] = useState({commentsCount: null, loading: false});
    const dispatch = useDispatch();

    useEffect(() => {
        retrieveUnresolvedCommentsCount();
        retrieveCommentsCount();
    }, []);

    const retrieveUnresolvedCommentsCount = async () => {
       setCount({...count, loading: true });
       const loCount =  await dispatch(getCommentsCount(selectedSubject?.id));
       setCount({
        ...count,
        unresolvedCount: loCount,
        loading: false,
       });
    }    
    const retrieveCommentsCount = async () => { 
        setCommentCount({...commentCount, loading: true})
        const data = await dispatch(retrieveComments(selectedSubject?.id));
        setCommentCount({
            ...commentCount,
            commentsCount: Number(data?.length> 0 ? data.length : 0),
            loading: false,
        });
     }

    const onNext = () => {
        if(!isRemoteConsent){
            navigation.navigate('Admin', {screen: 'SubjectDetails'})
        }
    }

    const onLogoutPress = () => {
        if(isRemoteConsent){
            navigation.dispatch(
            CommonActions.reset({
                index: 0,
                routes: [{ name: 'SubjectAuthStack' , params: { otp: subjectRemoteConsentDto?.otp, }},],
            })
            )
        }
    }

    if(count.loading || commentCount.loading){
        return (
                <ActivityIndicator
                  size="large"
                  color={Colors.ActivityIndicator}
                />
        )
    }
    if(count.unresolvedCount == 0 && commentCount.commentsCount && commentCount.commentsCount > 0) {
        return <SubjectCommentsResolvedScreen {...props}/>
    }
    if(commentCount.commentsCount!=null && commentCount.commentsCount == 0){
        return <SubDocsPage {...props}/>
    }
    return (
        <View style = {CommentsReviewStyles.containerStyle}>
            <ChapterTitle chapterTitle={screenProps.t('Congratulations')} chapterType = 'CONGRATULATIONS' displayComments = {false}/>
            <View style = {[CommentsReviewStyles.bodyStyle, {height: SCREEN_HEIGHT*0.72}]}>
                <View style={{  padding:5, paddingLeft:wp('2%'),}}>
                {isKnowledgeReviewChapterPresent(screenProps?.languages, "English", isKnowledgeReviewDone, selectedSubject.subjectConsent) && <Text>{screenProps.t('KnowledgeReviewCompletion')}</Text>}
                </View>
                <View style={{ padding:10, flex: 0.6, paddingLeft:wp('3%') }}>
                { isRemoteConsent && <Text note style={[styles.textNote, {fontSize: 14, padding: 5}]}>{screenProps.t('CommentsPending')} {email} or {phoneNo}</Text> }
                { isRemoteConsent && <Text note style={[styles.textNote, {fontSize: 14, padding: 5}]}>{screenProps.t('ConsentIncomplete')}</Text> }
                { !isRemoteConsent && <Text note style={[styles.textNote, {fontSize: 14, padding: 5}]}>{screenProps.t('InOfficeCommentsPending')}</Text>}
                </View>
                {isRemoteConsent && 
                <View>
                    <TryalButton 
                    title = {screenProps.t('Logout')}
                    onClick = {onLogoutPress}
                    buttonStyle={[constStyles.buttonStyle]}
                    buttonTxtStyle={[constStyles.buttonTxt]}
                    type={ButtonTypes.FILLED}
                    />
                </View> 
                }
            </View>
            {!isRemoteConsent && 
            <Footer style={chapterButtonStyles.footer}>
              <View>
                <TryalButton 
                  buttonStyle={chapterButtonStyles.button} 
                  buttonTxtStyle = {chapterButtonStyles.buttonTxt}
                  title = {screenProps.t('Logout')}
                  onClick = {async() => await dispatch(inOfficeLogout())}
                />
              </View>
            </Footer>
            }
        </View>
    )
}

export default CommentsReview;
